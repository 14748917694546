var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("input", {
        ref: "fileload",
        staticStyle: { display: "none" },
        attrs: {
          id: "fileload",
          accept: ".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx,.zip",
          type: "file",
        },
        on: { change: _vm.onChangeFileUpload },
      }),
      !_vm.withFavourite
        ? _c(
            "b-container",
            [
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isLoading,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: _vm.isDisabled,
                        size: "sm",
                        variant: "custom-blue",
                      },
                      on: { click: _vm.fileClick },
                    },
                    [
                      _c("img", {
                        staticClass: "mr-2",
                        attrs: { alt: "", src: "/images/clip_white.svg" },
                      }),
                      _vm._t("default"),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "b-container",
            [
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isLoading,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        text: _vm.btnText,
                        right: "",
                        size: "sm",
                        variant: "custom-blue",
                      },
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              _vm.showModal = true
                            },
                          },
                        },
                        [_vm._v("Из сохраненного")]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: { disabled: _vm.isDisabled },
                          on: { click: _vm.fileClick },
                        },
                        [_vm._v("Загрузить")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  ref: "select-doc-modal",
                  attrs: {
                    title: _vm.modalTitle,
                    "hide-header-close": "",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                    size: "lg",
                  },
                  on: {
                    show: function ($event) {
                      _vm.form.selected = []
                    },
                  },
                  model: {
                    value: _vm.showModal,
                    callback: function ($$v) {
                      _vm.showModal = $$v
                    },
                    expression: "showModal",
                  },
                },
                [
                  _vm._l(_vm.filesList, function (file) {
                    return _c(
                      "b-form-checkbox",
                      {
                        key: file.id,
                        staticClass: "grey-color my-2 fs-14",
                        attrs: { value: file.attachment },
                        model: {
                          value: _vm.form.selected,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "selected", $$v)
                          },
                          expression: "form.selected",
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(file.name) + " - "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.$getAttachmentDownloadLink(
                                file.attachment.id
                              ),
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(file.attachment.name))]
                        ),
                      ]
                    )
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { state: _vm.state } },
                    [_vm._v("Выберите один или несколько документов")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "w-100",
                      attrs: { slot: "modal-footer" },
                      slot: "modal-footer",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "my-3" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2 text-uppercase",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.cancelSelect },
                            },
                            [_vm._v("Отмена")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mx-2 text-uppercase",
                              attrs: {
                                disabled: !_vm.state,
                                variant: "custom-blue",
                              },
                              on: { click: _vm.saveData },
                            },
                            [_vm._v("Прикрепить")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                2
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { BaseApiService } from '@/services/backend/base';
import axios from 'axios';

export class PersonalProposalsApiService extends BaseApiService {
    constructor() {
        super('personal/proposals');
    }

    async getList(filters) {
        try {
            const response = await axios.get(this.getUrl(), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

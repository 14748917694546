import store from '@/store';

export class BaseApiService {
    baseUrl = process.env.VUE_APP_API_BASE_URL + '/api/front/v1';
    resource;

    constructor(resource) {
        if (!resource) throw new Error('Resource is not provided');
        this.resource = resource;
    }

    getUrl(id = '') {
        if (id) {
            return `${this.baseUrl}/${this.resource}/${id}`;
        } else {
            return `${this.baseUrl}/${this.resource}`;
        }
    }

    handleErrors(err) {
        // Note: here you may want to add your errors handling
        store.dispatch('showError', err).then(() => {});
    }
}

import { OrgRoles } from '@/const';

export default [
    {
        path: '/proposals/competitive/:id/show',
        component: () => import(/* webpackChunkName: "main" */ '@/components/proposals/competitive-proposal-show'),
        name: 'CompetitiveProposalShow',
        meta: { requiresAuth: true, orgRole: OrgRoles.SUPPLIER_COMPETITIVE },
    },
    {
        path: '/proposals/direct/:id/show',
        component: () => import(/* webpackChunkName: "main" */ '@/components/proposals/direct-proposal-show'),
        name: 'DirectProposalShow',
        meta: { requiresAuth: true, orgRole: OrgRoles.SUPPLIER_DIRECT },
    },
    {
        path: '/proposals/simple/:id/show',
        component: () => import(/* webpackChunkName: "main" */ '@/components/proposals/simple-proposal-show'),
        name: 'SimpleProposalShow',
        meta: { requiresAuth: true, orgRole: OrgRoles.SUPPLIER_SIMPLE },
    },
];

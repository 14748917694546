<template>
    <b-container>
        <b-row>
            <b-col>
                <p class="mt-3 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="{ name: 'NoticesList' }">Объявления о закупках</router-link>
                </p>
                <p class="mt-3 text-center">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="{ name: 'OrganizationsList' }">Организации </router-link>
                </p>
                <p class="mt-3 text-center">
                    <a :href="$links.info_materials" target="_blank" class="text-uppercase fw-600 grey-color text-decoration-none fs-14"> ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ </a>
                </p>
                <p class="mt-3 text-center">
                    <a :href="$links.legal_documents" target="_blank" class="text-uppercase fw-600 grey-color text-decoration-none fs-14"> Правовые документы </a>
                </p>

                <div class="d-flex flex-column align-items-center">
                    <register-button class="my-1" />
                    <router-link tag="button" exact class="text-uppercase text-nowrap btn btn-custom-blue my-1" v-if="!!$store.state.token" :to="{ name: 'Cabinet' }">Личный кабинет </router-link>
                    <login-button class="my-1" />
                    <router-link tag="button" class="text-uppercase text-nowrap btn btn-custom-outline-secondary my-1" v-if="!!$store.state.token" to="/auth/logout"> Выйти </router-link>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import RegisterButton from '@/components/auth/register-button.vue';
import LoginButton from '@/components/auth/login-button.vue';

export default {
    mounted() {
        let _this = this;
        document.getElementById('full-menu').addEventListener('click', function (e) {
            if (e.target.tagName.toLowerCase() === 'a') {
                _this.$emit('hide-menu');
            }
        });
    },
    components: {
        RegisterButton,
        LoginButton,
    },
    methods: {
        showModal() {
            this.$emit('show-modal');
        },
    },
};
</script>

<style scoped>
.full_menu-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.list-item {
    margin: 5px 0;
}

.full_menu-list .list-item a {
    color: var(--grey-color);
    font-size: 14px;
    text-decoration: none;
}
.router-link-active {
    color: var(--est-blue-2) !important;
}
</style>

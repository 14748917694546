import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

import store from '@/store';

import authRoutes from './auth';
import organizationsRoutes from './organizations';
import cabinetRoutes from './cabinets';
import purchasesRoutes from './purchases';
import proposalsRoutes from './proposals';

Vue.use(Router);
Vue.use(Meta);

const ROUTES = [
    { path: '', component: () => import(/* webpackChunkName: "main" */ '@/components/Home'), name: 'Home' },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "main" */ '@/components/elements/404'),
        name: '404',
    },
]
    .concat(authRoutes)
    .concat(organizationsRoutes)
    .concat(cabinetRoutes)
    .concat(purchasesRoutes)
    .concat(proposalsRoutes)
    .concat([{ path: '*', redirect: '/404' }]);

const router = new Router({
    base: '/',
    mode: 'history',
    routes: ROUTES,
    scrollBehavior(to, from, savedPosition) {
        if (to.path === from.path) {
            return;
        }

        let positionObject = { x: 0, y: 0 };

        if (savedPosition) {
            positionObject = savedPosition;
        }
        if (to.hash) {
            positionObject = { selector: to.hash };
        }
        if (from.path === to.path) {
            positionObject = {};
        }

        return positionObject;
    },
});

const orgRoleIsPermitted = (orgRole) => {
    if (typeof store.state.organization.roles !== 'undefined') {
        return store.state.organization.roles.includes(orgRole);
    } else {
        return false;
    }
};

router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.getters.isLoggedIn) {
            window.location.assign('/');
            // next({
            //     name: 'Login',
            //     query: {
            //         redirect: to.fullPath,
            //     },
            // });
        } else {
            if (to.meta.orgRole) {
                const orgRoles = Object.prototype.toString.call(to.meta.orgRole) === '[object Array]' ? to.meta.orgRole : [to.meta.orgRole];
                if (orgRoles.findIndex((r) => orgRoleIsPermitted(r)) > -1) {
                    next();
                } else {
                    next({ name: 'OrganizationInformation' });
                }
            } else {
                next();
            }
        }
    }

    if (store.getters.isLoggedIn && to.name !== 'Logout') {
        // await store.dispatch('getUserData');
        next();
    } else {
        localStorage.removeItem('user');
        localStorage.removeItem('organization');
        next();
    }
});

export default router;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.windowWidth >= 768 ? _c("top-info-line") : _vm._e(),
      _c("is-dev-env-bar"),
      _c(
        "b-container",
        { staticClass: "nav_line", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            { staticClass: "py-1" },
            [
              _c(
                "b-row",
                { staticClass: "d-flex flex-nowrap" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticStyle: { border: "none !important" },
                          attrs: { to: "/" },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/images/logo.png",
                              height: "80px",
                              alt: "logo",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "h-100 fast_links" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center desktop_link right_line",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-nowrap",
                                  attrs: { to: { name: "NoticesList" } },
                                },
                                [_vm._v("Объявления о закупках")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center desktop_link",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-center",
                                  attrs: { to: { name: "OrganizationsList" } },
                                },
                                [_vm._v("Организации")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "cursor-pointer grey-color",
                                  class: {
                                    "fas fa-times": _vm.menuVisible,
                                    "fas fa-bars": !_vm.menuVisible,
                                  },
                                  staticStyle: { width: "15px" },
                                  attrs: { "aria-controls": "full-menu" },
                                  on: {
                                    click: function ($event) {
                                      _vm.menuVisible = !_vm.menuVisible
                                    },
                                  },
                                },
                                [_c("span"), _c("span"), _c("span")]
                              ),
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center fast_auth",
                              staticStyle: { "margin-left": "35px" },
                            },
                            [
                              !_vm.$store.state.token
                                ? [_c("register-button"), _c("login-button")]
                                : [
                                    _c(
                                      "div",
                                      [
                                        !!_vm.$store.state.token
                                          ? _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "text-nowrap btn btn-custom-blue",
                                                attrs: {
                                                  to: { name: "Cabinet" },
                                                },
                                              },
                                              [_vm._v(" Личный кабинет ")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        !!_vm.$store.state.token
                                          ? _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "text-nowrap text-uppercase btn btn-custom-outline-secondary",
                                                attrs: {
                                                  tag: "button",
                                                  to: "/auth/logout",
                                                },
                                              },
                                              [_vm._v(" Выйти ")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          staticClass: "full-screen",
          attrs: { id: "full-menu" },
          model: {
            value: _vm.menuVisible,
            callback: function ($$v) {
              _vm.menuVisible = $$v
            },
            expression: "menuVisible",
          },
        },
        [
          _c(
            "div",
            [
              _vm.windowWidth >= 768
                ? _c(
                    "b-container",
                    { staticClass: "top_info-line py-4", attrs: { fluid: "" } },
                    [
                      _c("full-menu", {
                        on: {
                          "hide-menu": function ($event) {
                            _vm.menuVisible = false
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.windowWidth < 768
                ? _c(
                    "b-container",
                    { staticClass: "top_info-line py-4", attrs: { fluid: "" } },
                    [
                      _c("mobile-menu", {
                        on: {
                          "hide-menu": function ($event) {
                            _vm.menuVisible = false
                          },
                          "show-modal": function ($event) {
                            _vm.showModal = true
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.menuVisible
        ? _c("div", {
            staticClass: "backdrop",
            on: { click: _vm.toggleFullMenu },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
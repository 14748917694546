export default [
    {
        path: '/auth/register',
        component: () => import(/* webpackChunkName: "main" */ '@/components/auth/Register'),
        name: 'Register',
    },
    {
        path: '/auth/login_token',
        component: () => import(/* webpackChunkName: "main" */ '@/components/auth/login-with-rutoken.vue'),
        name: 'LoginRutoken',
    },
    {
        path: '/auth/logout',
        component: () => import(/* webpackChunkName: "main" */ '@/components/auth/Logout'),
        name: 'Logout',
    },
];

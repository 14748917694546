import 'babel-polyfill';
import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import store from './store';
import axios from 'axios';
window.axios = axios;
import BootstrapVue from 'bootstrap-vue';
import App from './App';
import router from './router';
import Default from '@/layout/Layout1';
import AuthLayout from '@/layout/AuthLayout';
import Multiselect from 'vue-multiselect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLock, faPencilAlt, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vuelidate from 'vuelidate';
import 'bootstrap/dist/css/bootstrap.css';
import Dialog from 'bootstrap-vue-dialog';
import FileUploader from '@/components/common/FileUploader';
import VueCurrencyInput from 'vue-currency-input';
import StopNumberMousewheelDirective from './directives/stop-number-mousewheel';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import momentMixin from '@/mixins/momentMixin';
import formatMixins from '@/mixins/formatMixins';
import toastMixin from '@/mixins/toast';
import globalDictionariesMixin from '@/mixins/globalDictionaries';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-select/dist/vue-select.css';

import '@/assets/custom.css';

import FormRowSpecificationTable from '@/components/common/form-rows/form-row-specification-table';
import FormRowSingleSelect from '@/components/common/form-rows/form-row-select-single';
import FormRowEditDate from '@/components/common/form-rows/form-row-edit-date';
import FormRowEditPrice from '@/components/common/form-rows/form-row-edit-price';
import FormRowEditText from '@/components/common/form-rows/form-row-edit-text';
import FormRowEditTextarea from '@/components/common/form-rows/form-row-edit-textarea';
import FormRowSingleLineDocumentUpload from '@/components/common/form-rows/form-row-single-line-document-upload';
import FormRowEditDadataAddress from '@/components/common/form-rows/form-row-edit-dadata-address';
import FormRowEditPhone from '@/components/common/form-rows/form-row-edit-phone';
import FormRowEditEmail from '@/components/common/form-rows/form-row-edit-email';
import FormRowEditCheckbox from '@/components/common/form-rows/form-row-edit-checkbox';
import FormRowSingleDocumentUploadNew from '@/components/common/form-rows/form-row-single-document-upload-new';

import TextHeader from '@/components/common/form-rows/text-header';
import TextRowLink from '@/components/common/form-rows/text-row-link';
import TextRow from '@/components/common/form-rows/text-row';
import TextRowPrice from '@/components/common/form-rows/text-row-price';
import TextRowDatetime from '@/components/common/form-rows/text-row-datetime';
import TextRowDate from '@/components/common/form-rows/text-row-date';
import TextRowDocuments from '@/components/common/form-rows/text-row-documents';
import TextRowAddresses from '@/components/common/form-rows/text-row-addresses';
import TextRowPhone from '@/components/common/form-rows/text-row-phone';

import FilterInputText from '@/components/common/filter-components/filter-input-text';
import FilterInputCurrency from '@/components/common/filter-components/filter-input-currency';
import FilterSingleSelect from '@/components/common/filter-components/filter-single-select';
import { $api } from '@/services/backend/api';

import PurchasesPreloader from '@/components/elements/PurchasesPreloader';
import NewsPreloader from '@/components/elements/NewsPreloader';
import SystemPreloader from '@/components/elements/SystemPreloader';
import EmptyRequest from '@/components/elements/EmptyRequest';
import ShowCertificateModal from '@/components/elements/ShowCertificateModal';
import ShowCertificateActModal from '@/components/elements/ShowCertificateActModal';
import VerificationEmailModal from '@/components/elements/VerificationEmailModal';

library.add(faPencilAlt, faTrash, faLock, faTrashAlt);

import vSelect from 'vue-select';
import VCalendar from 'v-calendar';
import TextRowDocument from '@/components/common/form-rows/text-row-document.vue';
import TextHeaderBig from '@/components/common/form-rows/text-header-big.vue';
import FormRowEditDatetime2 from '@/components/common/form-rows/form-row-edit-datetime-2.vue';
import SelectedCertificateInformation from '@/components/auth/selected-certificate-information.vue';
import FormRowUploadSingleDocument from '@/components/common/form-rows/form-row-upload-single-document.vue';
import RegistrationTabHeader from '@/components/auth/parts/registration-tab-header.vue';
import FormRowEditNumber from '@/components/common/form-rows/form-row-edit-number.vue';
import FormRowUploadMultipleDocuments from '@/components/common/form-rows/form-row-upload-multiple-documents.vue';

Vue.component('v-select', vSelect);
// Use v-calendar & v-date-picker components
Vue.component('registration-tab-header', RegistrationTabHeader);
Vue.component('form-row-edit-number', FormRowEditNumber);
Vue.component('form-row-upload-multiple-documents', FormRowUploadMultipleDocuments);
Vue.component('form-row-upload-single-document', FormRowUploadSingleDocument);
Vue.component('form-row-edit-datetime2', FormRowEditDatetime2);
Vue.component('selected-certificate-information', SelectedCertificateInformation);
Vue.component('text-header', TextHeader);
Vue.component('text-row', TextRow);
Vue.component('text-header-big', TextHeaderBig);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('multiselect', Multiselect);
Vue.component('default-layout', Default);
Vue.component('auth-layout', AuthLayout);
Vue.component('purchases-preloader', PurchasesPreloader);
Vue.component('news-preloader', NewsPreloader);
Vue.component('system-preloader', SystemPreloader);
Vue.component('empty-request', EmptyRequest);
Vue.component('certificate-modal', ShowCertificateModal);
Vue.component('certificate-act-modal', ShowCertificateActModal);
Vue.component('verification-email-modal', VerificationEmailModal);
Vue.component('file-uploader', FileUploader);
Vue.component('form-row-specification-table', FormRowSpecificationTable);
Vue.component('form-row-single-select', FormRowSingleSelect);
Vue.component('form-row-edit-date', FormRowEditDate);
Vue.component('form-row-edit-price', FormRowEditPrice);
Vue.component('form-row-edit-text', FormRowEditText);
Vue.component('form-row-edit-textarea', FormRowEditTextarea);
Vue.component('form-row-edit-dadata-address', FormRowEditDadataAddress);
Vue.component('form-row-edit-phone', FormRowEditPhone);
Vue.component('form-row-edit-email', FormRowEditEmail);
Vue.component('form-row-edit-checkbox', FormRowEditCheckbox);
Vue.component('form-row-single-line-document-upload', FormRowSingleLineDocumentUpload);
Vue.component('form-row-single-document-upload-new', FormRowSingleDocumentUploadNew);
Vue.component('text-header', TextHeader);
Vue.component('text-row', TextRow);
Vue.component('text-row-addresses', TextRowAddresses);
Vue.component('text-row-date', TextRowDate);
Vue.component('text-row-datetime', TextRowDatetime);
Vue.component('text-row-document', TextRowDocument);
Vue.component('text-row-documents', TextRowDocuments);
Vue.component('text-row-link', TextRowLink);
Vue.component('text-row-phone', TextRowPhone);
Vue.component('text-row-price', TextRowPrice);
Vue.component('filter-input-text', FilterInputText);
Vue.component('filter-input-currency', FilterInputCurrency);
Vue.component('filter-single-select', FilterSingleSelect);

Vue.directive('stop-number-mousewheel', StopNumberMousewheelDirective);

Vue.prototype.$http = axios;

Vue.prototype.$http.interceptors.request.use(
    async function (config) {
        if (!config.url.includes('auth/login') && !config.url.includes('auth/recall')) {
            config.headers['Authorization'] = 'Bearer ' + store.getters['token'];
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

Vue.prototype.$http.interceptors.response.use(undefined, function (error) {
    console.log('ERROR', error);
    if (error.request.status !== 401) {
        return Promise.reject(error);
    }
    if (error.request.responseURL.includes('auth/login') || error.request.responseURL.includes('auth/logout')) {
        return Promise.reject(error);
    }
    if (error.request.responseURL.includes('auth/logout') && error.request.status === 401) {
        window.location.assign('/');
    }
    if (error.request.responseURL.includes('auth/recall')) {
        store.dispatch('logout');
        window.location.assign('/');
    }
    if (error.request.status === 401) {
        window.location.assign('/');
    }
});

Vue.mixin({
    computed: {
        $api: () => $api,
    },
});
Vue.mixin(momentMixin);
Vue.mixin(formatMixins);
Vue.mixin(toastMixin);
Vue.mixin(globalDictionariesMixin);

const debug = process.env.NODE_ENV !== 'production';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL + '/api/front/v1';
// axios.defaults.headers.common['X-Atmo'] = process.env.VUE_APP_SECURITY_HEADER;

Vue.config.productionTip = false;

Vue.use(VueCurrencyInput);
Vue.use(BootstrapVue);
Vue.use(Dialog);
Vue.use(Vuelidate);
Vue.use(VCalendar);

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_KEY || 'GTM-TESTTES',
    debug: debug,
    loadScript: true,
    vueRouter: router,
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

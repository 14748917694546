import { BaseApiService } from '@/services/backend/base';
import axios from 'axios';

export class PersonalProfileApiService extends BaseApiService {
    constructor() {
        super('personal/profile');
    }

    async getInformation() {
        try {
            const response = await axios.get(this.getUrl());
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

export const __OFFERS_STATUS = {
    status_actual: 1,
    status_revoked: 2,
    status_declined: 3,
    status_winner: 4,
};
export const __PURCHASE_STATUS = {
    status_start: 1,
    status_protocol_formed: 2,
    status_contract_concluded: 3,
    status_contract_executed: 4,
    status_contract_not_executed: 5,
    status_contract_terminated: 6,
    status_cancel: 7,
    status_fail: 8,
    status_control: 9,
    status_contract_not_concluded: 10,
    status_draft: 11,
    status_blocked: 12,
    status_draft_save_signed: 13,
};
export const __CONTRACT_STATUS = {
    status_document_send: 1,
    status_document_signed_provider: 2,
    status_document_signed_provider_customer: 3,
    status_protocol_disagreement: 4,
};
export const __LINKS = {
    user_agreement: process.env.VUE_APP_LINK_USER_AGREEMENT,
    privacy_policy: process.env.VUE_APP_LINK_PRIVACY_POLICY,
    public_offer: process.env.VUE_APP_LINK_PUBLIC_OFFER,
    user_guide: process.env.VUE_APP_LINK_USER_GUIDE,
    useful_links: process.env.VUE_APP_LINK_USEFUL_LINKS,
    legal_documents: process.env.VUE_APP_LINK_LEGAL_DOCUMENTS,
    info_materials: process.env.VUE_APP_LINK_INFORMATION_MATERIALS,
    videos: process.env.VUE_APP_LINK_VIDEOS,
    faq: process.env.VUE_APP_LINK_FAQ,
    add_money: process.env.VUE_APP_LINK_ADD_MONEY,
    workbench_setup: process.env.VUE_APP_LINK_WORKBENCH_SETUP,
    prolong_certificate: process.env.VUE_APP_LINK_PROLONG_CERTIFICATE,
    marketplace_register: process.env.VUE_APP_MARKETPLACE_PROVIDER_REGISTER_LINK,
    telegram_events_bot: process.env.VUE_APP_LINK_TELEGRAM_EVENTS_BOT,
    ktru_requests_video_manual: process.env.VUE_APP_LINK_VIDEO_KTRU_REQUESTS,
    manual_ktru_requests_add: process.env.VUE_APP_LINK_MANUAL_KTRU_REQUESTS_ADD,
    purchase_categories: process.env.VUE_APP_LINK_PURCHASE_CATEGORIES,
    manual: {
        registration: process.env.VUE_APP_LINK_MANUAL_REGISTRATION,
        competitive_purchase_create: process.env.VUE_APP_LINK_MANUAL_COMPETITIVE_PURCHASE_CREATE,
        competitive_proposal_create: process.env.VUE_APP_LINK_MANUAL_COMPETITIVE_PROPOSAL_CREATE,
        competitive_purchase_protocol_forming: process.env.VUE_APP_LINK_MANUAL_COMPETITIVE_PURCHASE_PROTOCOL_FORMING,
        competitive_purchase_envelopes_opening: process.env.VUE_APP_LINK_MANUAL_COMPETITIVE_PURCHASE_ENVELOPES_OPENING,
        simple_purchase_create: process.env.VUE_APP_LINK_MANUAL_SIMPLE_PURCHASE_CREATE,
        simple_proposal_create: process.env.VUE_APP_LINK_MANUAL_SIMPLE_PROPOSAL_CREATE,
        simple_purchase_protocol_forming: process.env.VUE_APP_LINK_MANUAL_SIMPLE_PURCHASE_PROTOCOL_FORMING,
    },
};

export const __CONTRACT_SUPPLEMENTARY_AGREEMENT_STATUSES = [
    { id: 1, text: 'Отправлено поставщику на подписание' },
    { id: 2, text: 'Подписано поставщиком' },
    { id: 3, text: 'Подписано поставщиком и заказчиком' },
    { id: 4, text: 'Поставщик направил протокол разногласий' },
];

export const PurchaseStatus = {
    STATUS_START: 1,
    STATUS_PROTOCOL_FORMED: 2,
    STATUS_CONTRACT_CONCLUDED: 3,
    STATUS_CONTRACT_EXECUTED: 4,
    STATUS_CONTRACT_NOT_EXECUTED: 5,
    STATUS_CONTRACT_TERMINATED: 6,
    STATUS_CANCEL: 7,
    STATUS_FAIL: 8,
    STATUS_CONTROL: 9,
    STATUS_CONTRACT_NOT_CONCLUDED: 10,
    STATUS_DRAFT: 11,
    STATUS_BLOCKED: 12,
    STATUS_DRAFT_SAVE_SIGNED: 13,
};

export const RefundRequestStatus = [
    { id: 1, name: 'Отправлен' },
    { id: 2, name: 'Исполнен' },
    { id: 3, name: 'Отклонен' },
];

export const AccountingOperationType = [
    { id: 1, name: 'Пополнение' },
    { id: 2, name: 'Блокирование' },
    { id: 3, name: 'Разблокировка' },
    { id: 4, name: 'Списание комиссии' },
    { id: 5, name: 'Возврат средств' },
];

export const OrgRoles = {
    CUSTOMER_SIMPLE: 'customer_simple',
    CUSTOMER_COMPETITIVE: 'customer_competitive',
    CUSTOMER_DIRECT: 'customer_direct',
    SUPPLIER_SIMPLE: 'supplier_simple',
    SUPPLIER_COMPETITIVE: 'supplier_competitive',
    SUPPLIER_DIRECT: 'supplier_direct',
};

export const UserRoles = {
    DIRECTOR: 'director',
    ADMINISTRATOR: 'administrator',
    BUYER: 'buyer',
    DOCUMENT_FLOW: 'document_flow',
};

<template>
    <div>
        <input id="fileload" ref="fileload" accept=".doc,.docx,.pdf,.jpg,.jpeg,.xls,.xlsx,.zip" style="display: none" type="file" v-on:change="onChangeFileUpload" />
        <b-container v-if="!withFavourite">
            <b-overlay :show="isLoading" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                <b-button :disabled="isDisabled" size="sm" variant="custom-blue" @click="fileClick">
                    <img alt="" class="mr-2" src="/images/clip_white.svg" />
                    <slot></slot>
                </b-button>
            </b-overlay>
        </b-container>
        <b-container v-else>
            <b-overlay :show="isLoading" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                <b-dropdown :text="btnText" right size="sm" variant="custom-blue">
                    <b-dropdown-item @click="showModal = true">Из сохраненного</b-dropdown-item>
                    <b-dropdown-item :disabled="isDisabled" @click="fileClick">Загрузить</b-dropdown-item>
                </b-dropdown>
            </b-overlay>
            <b-modal ref="select-doc-modal" v-model="showModal" :title="modalTitle" hide-header-close no-close-on-backdrop no-close-on-esc size="lg" @show="form.selected = []">
                <!--                <b-form-checkbox-group v-model="form.selected" :options="filesList" name="documents" value-field="attachment" text-field="name" stacked class="grey-color" />-->
                <b-form-checkbox v-for="file in filesList" :key="file.id" v-model="form.selected" :value="file.attachment" class="grey-color my-2 fs-14">
                    {{ file.name }} - <a :href="$getAttachmentDownloadLink(file.attachment.id)" target="_blank">{{ file.attachment.name }}</a>
                </b-form-checkbox>
                <b-form-invalid-feedback :state="state">Выберите один или несколько документов</b-form-invalid-feedback>
                <div slot="modal-footer" class="w-100">
                    <div class="my-3">
                        <b-button class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelSelect">Отмена</b-button>
                        <b-button :disabled="!state" class="mx-2 text-uppercase" variant="custom-blue" @click="saveData">Прикрепить</b-button>
                    </div>
                </div>
            </b-modal>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'FileUploader',
    props: {
        isMain: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        withFavourite: {
            type: Boolean,
            default: false,
        },
        filesList: {
            type: Array,
        },
        btnText: {
            type: String,
            default: '',
        },
        modalTitle: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isLoading: false,
            file: null,
            returnFile: null,
            showModal: false,
            form: {
                selected: [],
            },
        };
    },
    methods: {
        fileClick() {
            this.$refs.fileload.value = '';
            this.file = null;
            this.returnFile = null;
            let evt = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            });
            this.$refs.fileload.dispatchEvent(evt);
        },
        async submitForm() {
            this.isLoading = true;
            let formData = new FormData();
            formData.append('file', this.file);
            await axios
                .post('/attachments', formData)
                .then((resp) => {
                    this.returnFile = resp.data;
                    // this.returnFile.isMain = this.$props.isMain;
                    this.sendFile();
                })
                .catch((err) => {
                    console.log(err);
                });
            this.isLoading = false;
        },
        sendFile() {
            this.$emit('set_file', this.returnFile);
        },
        onChangeFileUpload() {
            if (this.$refs.fileload.files.length > 0) {
                this.file = this.$refs.fileload.files[0];
                this.submitForm();
            }
        },
        cancelSelect() {
            this.$refs['select-doc-modal'].hide();
            this.showModal = false;
        },
        saveData() {
            this.$refs['select-doc-modal'].hide();
            this.showModal = false;
            this.$emit('set_file', this.form.selected);
        },
    },
    computed: {
        state() {
            return this.form.selected.length > 0;
        },
    },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "p",
              { staticClass: "text-uppercase fw-600 grey-color fs-14 mt-4" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: { to: { name: "NoticesList" } },
                  },
                  [_vm._v("Объявления о закупках")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "text-uppercase fw-600 grey-color fs-14 mt-4" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: { to: { name: "OrganizationsList" } },
                  },
                  [_vm._v("Организации ")]
                ),
              ],
              1
            ),
          ]),
          _c("b-col", [
            _c(
              "p",
              { staticClass: "text-uppercase fw-600 grey-color fs-14 mt-4" },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: { href: _vm.links.info_materials, target: "_blank" },
                  },
                  [_vm._v(" ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ ")]
                ),
              ]
            ),
            _c(
              "p",
              { staticClass: "text-uppercase fw-600 grey-color fs-14 mt-4" },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: {
                      href: _vm.links.legal_documents,
                      target: "_blank",
                    },
                  },
                  [_vm._v(" Правовые документы ")]
                ),
              ]
            ),
          ]),
          _c("b-col", [
            _c(
              "p",
              {
                staticClass:
                  "text-uppercase grey-color text-decoration-none fs-14 mt-4",
              },
              [_vm._v("Режим работы - Круглосуточно")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.certificate
    ? _c("div", [
        _c("h6", { staticClass: "grey-color font-weight-bold my-3" }, [
          _vm._v("Информация о выбранном сертификате"),
        ]),
        _c(
          "div",
          { staticClass: "fs-14 my-3" },
          [
            _c(
              "b-row",
              [
                _vm.certificate.subject.inn
                  ? _c("b-col", { staticClass: "py-1", attrs: { md: "6" } }, [
                      _c("strong", [_vm._v("ИНН")]),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.certificate.subject.inn) + " "),
                    ])
                  : _vm._e(),
                _vm.certificate.subject.organization_name
                  ? _c("b-col", { staticClass: "py-1", attrs: { md: "6" } }, [
                      _c("strong", [_vm._v("Организация")]),
                      _c("br"),
                      _vm._v(
                        " " +
                          _vm._s(_vm.certificate.subject.organization_name) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.certificate.subject.full_name
                  ? _c("b-col", { staticClass: "py-1", attrs: { md: "6" } }, [
                      _c("strong", [_vm._v("ФИО")]),
                      _c("br"),
                      _vm._v(
                        " " + _vm._s(_vm.certificate.subject.full_name) + " "
                      ),
                    ])
                  : _vm._e(),
                _vm.certificate.subject.position
                  ? _c("b-col", { staticClass: "py-1", attrs: { md: "6" } }, [
                      _c("strong", [_vm._v("Должность")]),
                      _c("br"),
                      _vm._v(
                        " " + _vm._s(_vm.certificate.subject.position) + " "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c("b-col", { staticClass: "py-1" }, [
                  _c("strong", [_vm._v("SHA1 отпечаток")]),
                  _c("br"),
                  _vm._v(
                    " " + _vm._s(_vm.certificate.prepared_thumbprint) + " "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c("b-col", { staticClass: "py-1" }, [
                  _c("strong", [_vm._v("Серийный номер")]),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.certificate.prepared_serial) + " "),
                ]),
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c("b-col", { staticClass: "py-1" }, [
                  _c("strong", [_vm._v("Срок действия")]),
                  _c("br"),
                  _vm._v(
                    " С " +
                      _vm._s(_vm.getDateFormat(_vm.certificate.valid.from)) +
                      " по " +
                      _vm._s(_vm.getDateFormat(_vm.certificate.valid.to)) +
                      " "
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div>
        <b-modal title="Электронные подписи" :visible="visible" size="lg" centered hide-footer @hidden="hidden">
            <div class="px-3" v-if="act && act.provider">
                <p class="fs-14 grey-color">
                    <img class="mr-3" src="/images/certificate.svg" alt="certificate" />
                </p>
                <b-row class="border-bottom py-3">
                    <b-col md="3" class="fs-14 grey-color"> Организация: </b-col>
                    <b-col md="9" class="fs-14 grey-color fw-600">
                        {{ act.organizationFinal.full_name }}
                    </b-col>
                </b-row>
                <b-row class="py-3">
                    <b-col md="3" class="fs-14 grey-color"> Дата и время: </b-col>
                    <b-col md="9" class="fs-14 grey-color fw-600">
                        {{ getDateTimeFormat(act.provider.x509certificateDate) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="bordered p-0">
                        <b-button v-b-toggle.certificate variant="custom-unstyle-grey" class="text-uppercase fw-600 w-100 d-flex justify-content-between">
                            {{ collapse ? 'Скрыть' : 'Показать' }} электронную подпись
                            <i class="fas" :class="{ 'fa-chevron-down': !collapse, 'fa-chevron-up': collapse }"></i>
                        </b-button>
                        <b-collapse id="certificate" class="mt-2">
                            <p class="fs-10 grey-color p-3 greyBg m-0" v-if="act.provider && act.provider.x509certificate">
                                {{ act.provider.x509certificate[0] }}
                            </p>
                        </b-collapse>
                    </b-col>
                </b-row>
            </div>
            <div class="px-3" v-if="act && act.customer">
                <p class="fs-14 grey-color">
                    <img class="mr-3" src="/images/certificate.svg" alt="certificate" />
                </p>
                <b-row class="border-bottom py-3">
                    <b-col md="3" class="fs-14 grey-color"> Организация: </b-col>
                    <b-col md="9" class="fs-14 grey-color fw-600">
                        {{ act.organization.full_name }}
                    </b-col>
                </b-row>
                <b-row class="py-3">
                    <b-col md="3" class="fs-14 grey-color"> Дата и время: </b-col>
                    <b-col md="9" class="fs-14 grey-color fw-600">
                        {{ getDateTimeFormat(act.customer.x509certificateDate) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="bordered p-0">
                        <b-button v-b-toggle.certificateCustomer variant="custom-unstyle-grey" class="text-uppercase fw-600 w-100 d-flex justify-content-between">
                            {{ collapse ? 'Скрыть' : 'Показать' }} электронную подпись
                            <i class="fas" :class="{ 'fa-chevron-down': !collapse, 'fa-chevron-up': collapse }"></i>
                        </b-button>
                        <b-collapse id="certificateCustomer" class="mt-2">
                            <p class="fs-10 grey-color p-3 greyBg m-0" v-if="act.customer && act.customer.x509certificate">
                                {{ act.customer.x509certificate[0] }}
                            </p>
                        </b-collapse>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: ['visible', 'act'],
    data() {
        return {
            collapse: false,
        };
    },
    methods: {
        hidden() {
            this.$emit('hidden');
        },
        showCollapse() {
            this.$root.$emit('bv::toggle::collapse', 'certificate');
            this.collapse = !this.collapse;
        },
    },
};
</script>

<style scoped>
.bordered {
    border: 1px solid rgba(149, 155, 164, 0.25);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
</style>

import DownloadFilesApi from '@/services/api/DownloadFiles';
import { __LINKS, OrgRoles, UserRoles } from '@/const';
import store from '@/store';

export default {
    methods: {
        orgRoleIsPermitted(orgRole) {
            if (typeof store.state.organization.roles !== 'undefined') {
                return store.state.organization.roles.includes(orgRole);
            } else {
                return false;
            }
        },
        userRolesPermitted(allowedRoles) {
            if (typeof store.state.user.role !== 'undefined') {
                return allowedRoles.includes(store.state.user.role.id);
            } else {
                return false;
            }
        },
        $formatPrice(value) {
            const val = (value / 100).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        $getAttachmentDownloadLink(id) {
            const baseUrl = process.env.VUE_APP_API_BASE_URL + '/api/front/v1';
            return `${baseUrl}/attachments/${id}`;
        },
        $parsePhone(text) {
            if (text) return text.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
            return '-';
        },
        getCurrentYear() {
            return new Date().getFullYear();
        },
        getCurrentDate() {
            return [new Date().getFullYear(), (new Date().getMonth() + 1 > 9 ? '' : '0') + (new Date().getMonth() + 1), (new Date().getDate() + 1 > 9 ? '' : '0') + (new Date().getDate() + 1)].join('-');
        },
        isObject(item) {
            return item && typeof item === 'object' && !Array.isArray(item) && item !== null;
        },
        $scrollToTop() {
            window.scrollTo(0, 0);
        },
        $scrollToHash() {
            // @ts-ignore
            this.$nextTick(() => {
                let el;
                // @ts-ignore
                if (this.$route.hash && (el = document.querySelector(this.$route.hash))) {
                    const y = el.getBoundingClientRect().top + window.pageYOffset - 255;
                    window.scrollTo({ top: y });
                }
            });
        },
        isDevEnv() {
            return process.env.NODE_ENV === 'development';
        },
        checkIfPurchaseCreator(purchase) {
            return store.getters.isLoggedIn && !store.getters.isProvider && store.state.user.organization_id === purchase.organization_id;
        },
        isPurchaseCreator(purchase) {
            return store.getters.isLoggedIn && !store.getters.isProvider && store.state.user.organization_id === purchase.organization_id;
        },
        isProvider() {
            return store.getters.isLoggedIn && store.getters.isProvider;
        },
        isCustomer() {
            return store.getters.isLoggedIn && !store.getters.isProvider;
        },
        isGuest() {
            return !store.getters.isLoggedIn;
        },
        $documentDownload(file) {
            DownloadFilesApi.documentDownload(file);
        },
        $commonDocumentDownload(file) {
            DownloadFilesApi.commonDocumentDownload(file);
        },
    },
    computed: {
        $links() {
            return __LINKS;
        },
        $orgRoles() {
            return OrgRoles;
        },
        $userRoles() {
            return UserRoles;
        },
    },
};

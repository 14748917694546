<template>
    <b-form-group :class="compact ? 'mb-0' : ''" :description="description" :label="label + ':'" :label-cols-lg="labelColsLg" :label-cols-sm="labelColsSm" :label-for="id" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <p :id="id" class="fs-14 grey-color my-auto col-form-label">{{ displayedValue }}</p>
    </b-form-group>
</template>

<script>
export default {
    name: 'text-row',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            // type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        compact: {
            default: false,
        },
        labelColsSm: {
            type: Number,
            default: 4,
        },
        labelColsLg: {
            type: Number,
            default: 3,
        },
        textIfEmpty: {
            type: String,
            default: 'Не заполнено',
        },
    },
    computed: {
        id() {
            return 'text_row_' + this._uid;
        },
        displayedValue() {
            if (this.value) {
                return this.value;
            }
            if (this.textIfEmpty) {
                return this.textIfEmpty;
            }
            return this.value;
        },
    },
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        class:
          "table-responsive " +
          (_vm.v !== null && _vm.v.$invalid ? "is-invalid" : ""),
      },
      [
        _c("table", { staticClass: "table" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.title) + ":")]),
              _c(
                "th",
                { attrs: { width: "1" } },
                [
                  _c(
                    "file-uploader",
                    {
                      attrs: { "is-main": true, "is-disabled": !!_vm.document },
                      on: { set_file: _vm.addFile },
                    },
                    [_vm._v("Загрузить")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.document
            ? _c("tbody", [
                _c("tr", [
                  _c(
                    "td",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "fs-14",
                          staticStyle: {
                            "padding-top": "0",
                            "padding-bottom": "0",
                            color: "var(--est-blue-2) !important",
                          },
                          attrs: { variant: "link", size: "xs" },
                          on: {
                            click: function ($event) {
                              return _vm.$commonDocumentDownload(_vm.document)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.document.name))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: { background: "transparent" },
                          attrs: { variant: "light", size: "sm" },
                          on: {
                            click: function ($event) {
                              return _vm.removeFile(0, true)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/images/trash_icon.svg",
                              alt: "trash_icon",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _c("tbody", [_vm._m(0)]),
        ]),
      ]
    ),
    _vm.v.$invalid
      ? _c("p", { staticClass: "invalid-feedback" }, [
          _vm._v("Прикрепите документ"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td", { attrs: { colspan: "2", align: "center" } }, [
        _vm._v("Не добавлено документов"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
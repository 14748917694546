var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preloader-backdrop" }, [
    _c(
      "svg",
      {
        staticStyle: {
          margin: "auto",
          background: "rgba(255, 255, 255, 0)",
          display: "block",
          "shape-rendering": "auto",
        },
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          width: "200px",
          height: "200px",
          viewBox: "0 0 100 100",
          preserveAspectRatio: "xMidYMid",
        },
      },
      [
        _c("g", { attrs: { transform: "rotate(0 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "-0.9166666666666666s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
        _c("g", { attrs: { transform: "rotate(30 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "-0.8333333333333334s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
        _c("g", { attrs: { transform: "rotate(60 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "-0.75s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
        _c("g", { attrs: { transform: "rotate(90 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "-0.6666666666666666s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
        _c("g", { attrs: { transform: "rotate(120 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "-0.5833333333333334s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
        _c("g", { attrs: { transform: "rotate(150 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "-0.5s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
        _c("g", { attrs: { transform: "rotate(180 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "-0.4166666666666667s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
        _c("g", { attrs: { transform: "rotate(210 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "-0.3333333333333333s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
        _c("g", { attrs: { transform: "rotate(240 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "-0.25s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
        _c("g", { attrs: { transform: "rotate(270 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "-0.16666666666666666s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
        _c("g", { attrs: { transform: "rotate(300 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "-0.08333333333333333s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
        _c("g", { attrs: { transform: "rotate(330 50 50)" } }, [
          _c(
            "rect",
            {
              attrs: {
                x: "47",
                y: "24",
                rx: "0",
                ry: "0",
                width: "6",
                height: "12",
                fill: "#005d9b",
              },
            },
            [
              _c("animate", {
                attrs: {
                  attributeName: "opacity",
                  values: "1;0",
                  keyTimes: "0;1",
                  dur: "1s",
                  begin: "0s",
                  repeatCount: "indefinite",
                },
              }),
            ]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "text-center" }, [
      _c(
        "p",
        { staticClass: "text-center mt-4 fs-28 white-color" },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import camelCase from 'lodash.camelcase';
import axios from 'axios';

const TTL = 5 * 60 * 1000;

const globalDictionariesModule = {
    namespaced: true,
    state: {
        deliverableGroups: [],
        purchaseStatuses: [],
        currencies: [],
        countries: [],
        userRoles: [],
        updatedAt: null,
        isUpdating: false,
    },
    mutations: {
        set(state, data) {
            for (const key in data) {
                const stateKey = camelCase(key);
                if (stateKey in state) {
                    state[stateKey] = data[key];
                }
            }
            state['updatedAt'] = new Date();
        },
        globalDictionariesStartedUpdating(state) {
            state.isUpdating = true;
        },
        globalDictionariesFinishedUpdating(state) {
            state.isUpdating = false;
        },
    },
    actions: {
        async populateGlobalDictionaries(context) {
            if (context.state.isUpdating === true) {
                return;
            }
            context.commit('globalDictionariesStartedUpdating');
            if (context.state.updatedAt === null || new Date() - new Date(context.state.updatedAt).getTime() > TTL) {
                const globalDictionariesResponse = await axios.post('/dictionaries');
                context.commit('set', globalDictionariesResponse.data?.data);
            }
            context.commit('globalDictionariesFinishedUpdating');
        },
    },
    getters: {
        globalDictionaries(state) {
            return {
                deliverableGroups: state.deliverableGroups,
                purchaseStatuses: state.purchaseStatuses,
                currencies: state.currencies,
                countries: state.countries,
                userRoles: state.userRoles,
            };
        },
    },
};

export default globalDictionariesModule;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$store.getters.isLoggedIn
    ? _c(
        "b-modal",
        {
          ref: "auth-modal",
          attrs: {
            title: "Регистрация",
            visible: _vm.showModal,
            "hide-footer": "",
            centered: "",
          },
          on: { close: _vm.close },
        },
        [
          _c("div", { staticClass: "d-block text-center w-100 m-auto" }, [
            _c("h4", [_vm._v("Уважаемый пользователь!")]),
          ]),
          _c("div", { staticClass: "d-block text-justify w-100 mb-4" }, [
            _c("p", { staticClass: "grey-color" }, [
              _vm._v(" Прохождение процедуры регистрации означает согласие с "),
              _c(
                "a",
                {
                  staticStyle: {
                    color: "var(--est-blue-2) !important",
                    "text-decoration": "underline !important",
                  },
                  attrs: { href: _vm.$links.user_agreement, target: "_blank" },
                },
                [_vm._v("Пользовательским соглашением")]
              ),
              _vm._v(", "),
              _c(
                "a",
                {
                  staticStyle: {
                    color: "var(--est-blue-2) !important",
                    "text-decoration": "underline !important",
                  },
                  attrs: { href: _vm.$links.privacy_policy, target: "_blank" },
                },
                [_vm._v("Политикой конфиденциальности")]
              ),
              _vm._v(" и ознакомление с "),
              _c(
                "a",
                {
                  staticStyle: {
                    color: "var(--est-blue-2) !important",
                    "text-decoration": "underline !important",
                  },
                  attrs: { href: _vm.$links.user_guide, target: "_blank" },
                },
                [_vm._v("Руководством пользователя")]
              ),
              _vm._v(". "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "d-block text-center w-100 mb-5" },
            [
              _c(
                "b-form-group",
                { attrs: { state: _vm.isCheckRules } },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { state: _vm.isCheckRules },
                      model: {
                        value: _vm.readedDocumetation,
                        callback: function ($$v) {
                          _vm.readedDocumetation = $$v
                        },
                        expression: "readedDocumetation",
                      },
                    },
                    [
                      _c("span", { staticClass: "grey-color fw-600" }, [
                        _vm._v("Подтверждаю ознакомление с материалами"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-block text-center w-75 m-auto" },
            [
              _c(
                "b-link",
                {
                  staticClass:
                    "btn btn-block btn-custom-blue mb-4 text-uppercase",
                  attrs: {
                    to: "/auth/register",
                    disabled: !_vm.readedDocumetation,
                  },
                  on: { click: _vm.close },
                },
                [_vm._v(" Регистрация ")]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "mt-5" }, [
            _c("p", { staticClass: "m-0 fs-12 grey-color text-center" }, [
              _vm._v('Уже зарегистрированы? Используйте кнопку "Вход в ЛК"'),
            ]),
          ]),
          _c("div", { staticClass: "mt-3" }, [
            _c("p", { staticClass: "m-0 fs-12 grey-color text-center" }, [
              _c(
                "a",
                {
                  staticClass: "grey-color",
                  staticStyle: {
                    color: "var(--est-blue-2) !important",
                    "text-decoration": "underline !important",
                  },
                  attrs: { href: _vm.$links.workbench_setup, target: "_blank" },
                },
                [
                  _vm._v(
                    "Инструкция по настройке рабочего места и началу работы"
                  ),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
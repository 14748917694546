var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "label-cols-sm": _vm.labelColsSm,
        "label-cols-lg": _vm.labelColsLg,
        description: _vm.description,
        label: _vm.label + ":",
        "label-for": _vm.id,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _c(
        "p",
        {
          staticClass: "fs-14 grey-color my-auto col-form-label",
          attrs: { id: _vm.id },
        },
        [
          _c(
            "a",
            { attrs: { href: _vm.$getAttachmentDownloadLink(_vm.value.id) } },
            [_vm._v(_vm._s(_vm.value.name))]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
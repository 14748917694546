<template>
    <div>
        <top-info-line v-if="windowWidth >= 768" />
        <is-dev-env-bar />
        <b-container fluid class="nav_line">
            <b-container class="py-1">
                <b-row class="d-flex flex-nowrap">
                    <b-col cols="auto">
                        <router-link to="/" style="border: none !important">
                            <img src="/images/logo.png" height="80px" alt="logo" />
                        </router-link>
                    </b-col>
                    <b-col cols="auto" class="ml-auto">
                        <b-row class="h-100 fast_links">
                            <b-col class="d-flex align-items-center desktop_link right_line">
                                <router-link class="text-nowrap" :to="{ name: 'NoticesList' }">Объявления о закупках</router-link>
                            </b-col>
                            <b-col class="d-flex align-items-center desktop_link">
                                <router-link class="text-center" :to="{ name: 'OrganizationsList' }">Организации</router-link>
                            </b-col>
                            <b-col class="d-flex justify-content-center align-items-center">
                                <i style="width: 15px" class="cursor-pointer grey-color" :class="{ 'fas fa-times': menuVisible, 'fas fa-bars': !menuVisible }" aria-controls="full-menu" @click="menuVisible = !menuVisible">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </i>
                            </b-col>
                            <b-col class="d-flex align-items-center fast_auth" style="margin-left: 35px">
                                <template v-if="!$store.state.token">
                                    <register-button />
                                    <login-button />
                                </template>
                                <template v-else>
                                    <div>
                                        <router-link class="text-nowrap btn btn-custom-blue" v-if="!!$store.state.token" :to="{ name: 'Cabinet' }"> Личный кабинет </router-link>
                                    </div>
                                    <div>
                                        <router-link tag="button" class="text-nowrap text-uppercase btn btn-custom-outline-secondary" v-if="!!$store.state.token" to="/auth/logout"> Выйти </router-link>
                                    </div>
                                </template>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-collapse id="full-menu" class="full-screen" v-model="menuVisible">
            <div>
                <b-container v-if="windowWidth >= 768" fluid class="top_info-line py-4">
                    <full-menu v-on:hide-menu="menuVisible = false"></full-menu>
                </b-container>
                <b-container v-if="windowWidth < 768" fluid class="top_info-line py-4">
                    <mobile-menu v-on:hide-menu="menuVisible = false" v-on:show-modal="showModal = true"> </mobile-menu>
                </b-container>
            </div>
        </b-collapse>
        <div v-if="menuVisible" class="backdrop" @click="toggleFullMenu"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FullMenu from './elements/FullLandingMenu';
import MobileMenu from './elements/MobileMenu';
import IsDevEnvBar from './navbar/is-dev-env-bar.vue';
import TopInfoLine from './navbar/top-info-line.vue';
import RegisterButton from '@/components/auth/register-button.vue';
import LoginButton from '@/components/auth/login-button.vue';

export default {
    components: {
        FullMenu,
        MobileMenu,
        IsDevEnvBar,
        TopInfoLine,
        RegisterButton,
        LoginButton,
    },
    name: 'AppLayoutNavbar',
    data() {
        return {
            form: {
                email: '',
                theme: '',
                text: '',
            },
            feedSucess: false,
            showFeedModal: false,
            showModal: false,
            menuVisible: false,
            windowWidth: null,
        };
    },
    created() {
        this.windowWidth = window.innerWidth;
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    props: {},
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        showMessage(message) {
            this.pushToast({
                title: 'Уведомление',
                text: message,
                timer: 5000,
            });
        },
        toggleFullMenu() {
            this.menuVisible = !this.menuVisible;
        },
        goToLogin() {
            this.$route.path.match('/auth/login') ? this.$router.go() : this.$router.push('/auth/login').catch(() => {});
        },
    },
    computed: {
        ...mapGetters({
            user: 'getUser',
        }),
    },
    watch: {
        $route: function () {
            this.menuVisible = false;
            this.showModal = false;
        },
    },
};
</script>

<style scoped>
i:hover {
    color: var(--est-blue-2);
}

.center-768 a:hover {
    color: var(--est-blue-2);
}

.fast_links div + div {
    margin-left: 10px;
}

.top_info-line {
    background-color: var(--greyBg-color);
}

.fast_links div > a {
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--grey-color);
    font-weight: 600;
    text-decoration: none;
    border-bottom: 2px solid transparent;
}

.right_line > a::after {
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    background-color: #f5f5f6;
    position: absolute;
    right: -20px;
    top: 0;
}

.right_line:last-child > a::after {
    content: none;
    display: none;
}

.nav_line {
    background-color: white;
    -webkit-box-shadow: 0 2px 5px -4px #aaa;
    -moz-box-shadow: 0 2px 5px -4px #aaa;
    box-shadow: 0 2px 5px -4px #aaa;
}

.full-screen {
    position: absolute;
    width: 100%;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: -1;
}

.full_menu-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.list-item {
    margin: 5px 0;
}

.full_menu-list .list-item a {
    color: var(--grey-color);
    font-size: 14px;
    text-decoration: none;
}

.router-link-active {
    color: #333333 !important;
    border-bottom: 2px solid #333 !important;
}
@media screen and (max-width: 1200px) {
    .desktop_link {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .times {
        display: none !important;
    }

    .center-768 {
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        margin: 0 !important;
    }

    .btn-custom-blue {
        background-color: var(--est-blue-2) !important;
        color: #fff !important;
        min-width: 130px;
        padding: 10px 20px !important;
        line-height: 1 !important;
        font-size: 14px !important;
    }

    .btn-custom-blue:hover {
        background-color: var(--est-blue-2) !important;
        border-color: var(--est-blue-2) !important;
    }

    .fast_auth {
        display: none !important;
    }
}

.btn-custom-blue,
.btn-custom-blue.router-link-active {
    background-color: var(--est-blue-2) !important;
    color: #fff !important;
    min-width: 130px;
    padding: 10px 20px !important;
    line-height: 1 !important;
    font-size: 14px !important;
    border-color: var(--est-blue-2) !important;
    border-bottom-width: 1px !important;
}

.btn-custom-blue:hover {
    background-color: #fff !important;
    color: var(--est-blue-2) !important;
    border-color: var(--est-blue-2) !important;
}
</style>

<style>
.nav_line .fas span {
    display: block;
    width: 18px;
    height: 2px;
    background: var(--grey-color);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.nav_line .fa-bars span + span {
    margin-top: 3px;
}
.nav_line .fa-times span {
    -webkit-transform: rotate(-45deg) translateY(-1px);
    -moz-transform: rotate(-45deg) translateY(-1px);
    -ms-transform: rotate(-45deg) translateY(-1px);
    -o-transform: rotate(-45deg) translateY(-1px);
    transform: rotate(-45deg) translateY(-1px);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
}
.nav_line .fa-times span:nth-child(1) {
    display: none;
}
.nav_line .fa-times span:nth-child(2) {
    -webkit-transform: rotate(45deg) translateY(1px);
    -moz-transform: rotate(45deg) translateY(1px);
    -ms-transform: rotate(45deg) translateY(1px);
    -o-transform: rotate(45deg) translateY(1px);
    transform: rotate(45deg) translateY(1px);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "h2",
    { class: _vm.headerClass + " " + _vm.marginClass },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-form-group",
        {
          staticClass: "mb-0",
          attrs: {
            "label-cols-sm": _vm.labelColsSm,
            "label-cols-lg": _vm.labelColsLg,
            label: _vm.title + ":",
            "label-class":
              "fs-14 grey-color fw-700 d-flex align-items-baseline",
          },
        },
        [
          !!_vm.document
            ? [
                _c(
                  "a",
                  {
                    staticClass: "fs-14 col-form-label d-inline-block",
                    attrs: {
                      href: _vm.$getAttachmentDownloadLink(_vm.document.id),
                    },
                  },
                  [_vm._v(_vm._s(_vm.document.name))]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "float-right",
                    staticStyle: { background: "transparent" },
                    attrs: {
                      variant: "light",
                      size: "sm",
                      disabled: _vm.disabled,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.removeFile(0, true)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/images/trash_icon.svg",
                        alt: "trash_icon",
                      },
                    }),
                  ]
                ),
              ]
            : [
                !_vm.state
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "col-form-label text-danger d-inline-block",
                        staticStyle: {
                          "font-size": "80%",
                          "line-height": "23px",
                        },
                      },
                      [_vm._v("Прикрепите документ")]
                    )
                  : _vm._e(),
                _c(
                  "file-uploader",
                  {
                    staticClass: "d-inline-block float-right",
                    attrs: {
                      "is-main": true,
                      "is-disabled": !!_vm.document || _vm.disabled,
                    },
                    on: { set_file: _vm.addFile },
                  },
                  [_vm._v("Загрузить")]
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
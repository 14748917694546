var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      staticClass: "w-100",
      attrs: {
        label: _vm.label + ":",
        description: _vm.description,
        state: _vm.state,
        "label-cols-sm": "4",
        "label-cols-lg": "3",
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _c("currency-input", {
        directives: [
          {
            name: "stop-number-mousewheel",
            rawName: "v-stop-number-mousewheel",
          },
        ],
        staticClass: "form-control fs-14",
        class: _vm.v !== null ? (_vm.state ? "is-valid" : "is-invalid") : "",
        attrs: { currency: null, locale: "ru-KG", "value-as-integer": "" },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      !_vm.state
        ? [
            _c("b-form-invalid-feedback", { attrs: { "force-show": "" } }, [
              _vm._v(_vm._s(_vm.errorMessage)),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
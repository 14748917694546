<template>
    <div class="d-flex align-items-center head_register-list">
        <span class="fs-52 fw-600">{{ number }}</span>
        <span class="pl-2 fs-18">
            <slot></slot>
        </span>
        <span v-b-popover.hover="'На вкладке присутствуют незаполненные поля'" class="badge badge-danger ml-auto" v-if="hasErrors">!</span>
    </div>
</template>

<script>
export default {
    name: 'registration-tab-header',
    props: {
        number: {
            type: String,
            default: '',
        },
        hasErrors: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
                <h2 class="grey-color font-weight-bold my-3" :class="headerClass">{{ title }}</h2>
                <file-uploader :is-main="true" v-if="!document" @set_file="addFile">Загрузить</file-uploader>
            </b-col>
        </b-row>
        <b-row class="py-2" no-gutters>
            <b-col class="grey-color">
                <div :class="v.$invalid ? 'is-invalid' : ''">
                    <table class="table" v-if="document">
                        <thead>
                            <tr>
                                <th>Наименование</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <a :href="$getAttachmentDownloadLink(document.id)" target="_blank">{{ document.name }}</a>
                                </td>
                                <td>
                                    <b-button @click="removeFile(0, true)" variant="light" size="sm" style="background: transparent">
                                        <img src="/images/trash_icon.svg" alt="trash_icon" />
                                    </b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table" v-else>
                        <thead>
                            <tr>
                                <th>Наименование</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="2" align="center">Не добавлено документов</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p v-if="v.$invalid" class="invalid-feedback">Прикрепите документ</p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'FormRowUploadSingleDocument',
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
        },
        v: {
            type: Object,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        headerClass: {
            type: String,
            default: 'fs-32',
        },
    },
    data() {
        return {
            visible: [],
        };
    },
    methods: {
        addFile(file) {
            this.document = file;
        },
        removeFile() {
            this.document = null;
        },
    },
    computed: {
        document: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>

import { BaseApiService } from '@/services/backend/base';
import axios from 'axios';
import store from '@/store';

export class PersonalUsersApiService extends BaseApiService {
    constructor() {
        super('personal/users');
    }

    async getList(filters) {
        try {
            const response = await axios.get(this.getUrl(), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async get(id) {
        try {
            const response = await axios.get(this.getUrl(id));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async update(id, data) {
        try {
            const response = await axios.post(this.getUrl(id), data);
            await store.dispatch('showSuccessToast', 'Изменения сохранены');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async create(data) {
        try {
            const response = await axios.post(this.getUrl(), data);
            await store.dispatch('showSuccessToast', 'Пользователь успешно создан');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async checkEmail(email) {
        try {
            await axios.post(this.getUrl('check_email'), { email: email });
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async block(id) {
        try {
            await axios.post(this.getUrl(id) + '/block');
            await store.dispatch('showSuccessToast', 'Пользователь заблокирован');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async unblock(id) {
        try {
            await axios.post(this.getUrl(id) + '/unblock');
            await store.dispatch('showSuccessToast', 'Пользователь разблокирован');
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

<template>
    <b-container>
        <b-row>
            <!--      <b-col>-->
            <!--        <p class="mt-4">-->
            <!--          <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="'/purchases'">Извещения </router-link>-->
            <!--        </p>-->
            <!--        <ul class="full_menu-list">-->
            <!--          <li class="list-item">-->
            <!--            <router-link :to="'/purchases/new'">Идет прием предложений</router-link>-->
            <!--          </li>-->
            <!--          <li class="list-item">-->
            <!--            <router-link :to="'/purchases/end'">Подведены итоги</router-link>-->
            <!--          </li>-->
            <!--          <li class="list-item">-->
            <!--            <router-link :to="'/purchases/complete'">Договор заключен</router-link>-->
            <!--          </li>-->
            <!--          <li class="list-item">-->
            <!--            <router-link :to="'/purchases/cancel'">Закупка отменена</router-link>-->
            <!--          </li>-->
            <!--          <li class="list-item">-->
            <!--            <router-link :to="'/purchases/not'">Закупка не состоялась</router-link>-->
            <!--          </li>-->
            <!--        </ul>-->
            <!--      </b-col>-->
            <b-col>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="{ name: 'NoticesList' }">Объявления о закупках</router-link>
                </p>
                <!--                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">-->
                <!--                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="'/contracts'">ДОГОВОРЫ </router-link>-->
                <!--                </p>-->
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">
                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="{ name: 'OrganizationsList' }">Организации </router-link>
                </p>
                <!--        <ul class="full_menu-list">-->
                <!--          <li class="list-item">-->
                <!--            <router-link :to="'/clients/customers'">Реестр заказчиков</router-link>-->
                <!--          </li>-->
                <!--          <li class="list-item">-->
                <!--            <router-link :to="'/clients/providers'">Реестр поставщиков</router-link>-->
                <!--          </li>-->
                <!--        </ul>-->
                <!--        <p class="mt-4">-->
                <!--          <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="'/quotation-requests'">Запросы КП </router-link>-->
                <!--        </p>-->
                <!--        <p class="mt-4">-->
                <!--          <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="'/production-requests'"> Поиск субподрядчиков </router-link>-->
                <!--        </p>-->
            </b-col>
            <b-col>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">
                    <a :href="links.info_materials" target="_blank" class="text-uppercase fw-600 grey-color text-decoration-none fs-14"> ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ </a>
                </p>
                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">
                    <a :href="links.legal_documents" target="_blank" class="text-uppercase fw-600 grey-color text-decoration-none fs-14"> Правовые документы </a>
                </p>
                <!--                <p class="text-uppercase fw-600 grey-color fs-14 mt-4">-->
                <!--                    <router-link class="text-uppercase fw-600 grey-color text-decoration-none fs-14" :to="'/articles'"> НОВОСТИ </router-link>-->
                <!--                </p>-->
            </b-col>
            <b-col>
                <p class="text-uppercase grey-color text-decoration-none fs-14 mt-4">Режим работы - Круглосуточно</p>
                <!--                <ul class="full_menu-list">-->
                <!--                    <li class="list-item">-->
                <!--                        <p class="grey-color fs-14">9:00 до 18:00 (по времени г.Уфа)</p>-->
                <!--                    </li>-->
                <!--                </ul>-->
                <!--        <b-btn variant="custom-blue" class="text-uppercase text-decoration-none fs-14 mt-4" @click="loginAsCustomer" v-if="test_zak"> Войти как Заказчик </b-btn>-->
                <!--        <b-btn variant="custom-blue" class="text-uppercase text-decoration-none fs-14 mt-4" @click="loginAsProvider" v-if="test_post"> Войти как Поставщик </b-btn>-->
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { __LINKS } from '@/const';

export default {
    data() {
        return {
            test_zak: !!process.env.VUE_APP_TEST_ZAK_EMAIL,
            test_post: !!process.env.VUE_APP_TEST_POST_EMAIL,
        };
    },
    created() {},
    mounted() {
        let _this = this;
        document.getElementById('full-menu').addEventListener('click', function (e) {
            if (e.target.tagName.toLowerCase() === 'a') {
                _this.$emit('hide-menu');
            }
        });
    },
    methods: {
        loginAsCustomer() {
            // console.log('login as customer');
            axios
                .post('/auth/login', {
                    email: process.env.VUE_APP_TEST_ZAK_EMAIL,
                    thumbprint: process.env.VUE_APP_TEST_ZAK_THUMB,
                })
                .then((response) => {
                    if (response.data.status === 'success') {
                        // устанавливаем выбранный сертификат в храниилище
                        // this.$store.commit('set_current_certificate', {index: this.certIndex, thumbprint: thumbprint});
                        this.$store
                            .dispatch('login', {
                                token: response.data.access_token,
                                user: response.data.user,
                                organization: response.data.organization,
                            })
                            .then(() => {
                                window.location.assign('/cabinet');
                            });
                    } else {
                        this.pushToast({
                            text: response.data.message,
                            title: 'Ошибка',
                            variant: 'danger',
                        });
                    }
                })
                .catch((error) => {
                    this.pushToast({
                        text: error,
                        title: 'Ошибка',
                        variant: 'danger',
                    });
                });
        },
        loginAsProvider() {
            // console.log('login as provider');
            axios
                .post('/auth/login', {
                    email: process.env.VUE_APP_TEST_POST_EMAIL,
                    thumbprint: process.env.VUE_APP_TEST_POST_THUMB,
                })
                .then((response) => {
                    if (response.data.status === 'success') {
                        // устанавливаем выбранный сертификат в храниилище
                        // this.$store.commit('set_current_certificate', {index: this.certIndex, thumbprint: thumbprint});
                        this.$store
                            .dispatch('login', {
                                token: response.data.access_token,
                                user: response.data.user,
                                organization: response.data.organization,
                            })
                            .then(() => {
                                window.location.assign('/cabinet');
                            });
                    } else {
                        this.pushToast({
                            text: response.data.message,
                            title: 'Ошибка',
                            variant: 'danger',
                        });
                    }
                })
                .catch((error) => {
                    this.pushToast({
                        text: error,
                        title: 'Ошибка',
                        variant: 'danger',
                    });
                });
        },
    },
    computed: {
        ...mapGetters({
            informationSections: 'getInformation',
        }),
        links() {
            return __LINKS;
        },
    },
};
</script>

<style scoped>
.full_menu-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.list-item {
    margin: 5px 0;
}

.full_menu-list .list-item a {
    color: var(--grey-color);
    font-size: 14px;
    text-decoration: none;
}
.router-link-active {
    color: var(--est-blue-2) !important;
}
.full_menu-feed:hover {
    color: var(--est-blue-2);
}
</style>

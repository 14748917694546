var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-wrapper layout-1" }, [
    _c("div", { staticClass: "layout-inner" }, [
      _c("div", { staticClass: "layout-container" }, [
        _c("div", { staticClass: "layout-content" }, [
          _c(
            "div",
            {
              staticClass:
                "router-transitions container-fluid flex-grow-1 container-p-y",
            },
            [_c("router-view")],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
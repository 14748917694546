import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import Offers from '@/services/api/Offers';
import storePlugins from '@/store/plugins/storePlugins';
import axios from 'axios';

import globalDictionariesModule from '@/store/globalDictionaries';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

const buildOptions = (text = null, title = null, variant = 'orange', boldText = null, link = null, timer = 10000, linkText = null) => {
    return {
        id: new Date().getTime() + Math.floor(Math.random() * (100 - 1) + 1),
        title: title,
        variant: variant,
        boldText: boldText,
        text: text,
        link: link,
        timer: timer,
        linkText: linkText,
    };
};

const store = new Vuex.Store({
    state: {
        packageVersion: 'v' + process.env.PACKAGE_VERSION || '',
        status: '',
        purchase: {},
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user') !== '' && localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : '{}',
        organization:
            localStorage.getItem('organization') !== '' && localStorage.getItem('organization') !== 'undefined' // организация пользоватея
                ? JSON.parse(localStorage.getItem('organization'))
                : '{}',
        organization_has_payments: false,
        certificate_valid_to: null,
        certificate_need_to_be_prolonged: null,
        currentCertificate: localStorage.getItem('current_certificate') !== '' && localStorage.getItem('current_certificate') !== 'undefined' ? JSON.parse(localStorage.getItem('current_certificate')) : null,
        isWorking: false,
        informationSection: [],
        toasts: [],
        offerDeclineStatuses: [],
        showVerificationModal: null,
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading';
        },
        auth_success(state, token) {
            state.status = 'success';
            state.token = token;
            localStorage.setItem('token', token);
        },
        auth_error(state) {
            state.status = 'error';
            state.token = '';
        },
        logout(state) {
            state.status = '';
            state.token = '';
            state.user = {};
        },
        set_user(state, user) {
            state.user = user;
        },
        set_is_working(state, is_working) {
            state.isWorking = is_working;
        },
        set_organization(state, organization) {
            state.organization = organization;
        },
        set_organization_has_payments(state, organization_has_payments) {
            state.organization_has_payments = organization_has_payments;
        },
        set_certificate_valid_to(state, certificate_valid_to) {
            state.certificate_valid_to = certificate_valid_to;
        },
        set_certificate_need_to_be_prolonged(state, certificate_need_to_be_prolonged) {
            state.certificate_need_to_be_prolonged = certificate_need_to_be_prolonged;
        },
        set_purchase(state, purchase) {
            state.purchase = purchase;
        },
        set_current_certificate(state, data) {
            const jsonData = JSON.stringify(data);
            state.currentCertificate = data;
            localStorage.setItem('current_certificate', jsonData);
        },
        setInformationToStore(state, payload) {
            state.informationSection = payload;
        },
        pushToast(state, payload) {
            state.toasts.unshift(payload);
        },
        removeToastFromStore(state, payload) {
            let index = state.toasts.findIndex((el) => el.id === payload);
            state.toasts.splice(index, 1);
        },
        updateOfferDeclineStatuses(state, offerDeclineStatuses) {
            state.offerDeclineStatuses = offerDeclineStatuses;
        },
        set_show_verification_modal(state, value) {
            state.showVerificationModal = value;
        },
    },
    actions: {
        login({ commit }, data) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('organization', JSON.stringify(data.organization));
            window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
            commit('auth_success', data.token);
            commit('set_user', data.user);
            commit('set_is_working', data.is_working);
            commit('set_organization', data.organization);
            commit('set_organization_has_payments', data.has_payments);
            commit('set_certificate_valid_to', data.certificate_valid_to);
            commit('set_certificate_need_to_be_prolonged', data.certificate_need_to_be_prolonged);
        },
        set_purchase({ commit }, data) {
            commit('set_purchase', data);
        },
        set_can_sign({ commit }, data) {
            commit('set_can_sign', data);
        },
        async logout({ commit }) {
            commit('logout');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('organization');
            localStorage.removeItem('current_certificate');
            localStorage.removeItem('can_sign');
            delete window.axios.defaults.headers.common['Authorization'];
        },
        getUserData({ commit }) {
            return new Promise((resolve, reject) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
                axios({
                    url: '/auth/recall',
                    method: 'POST',
                })
                    .then((resp) => {
                        const { user, organization } = resp.data;
                        // localStorage.setItem('token', token);
                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('organization', JSON.stringify(organization));
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
                        commit('auth_success', localStorage.getItem('token'));
                        commit('set_user', user);
                        commit('set_show_verification_modal', user.show_verification_modal);
                        resolve(resp);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setInformation({ commit }, payload) {
            commit('setInformationToStore', payload);
        },
        addToast({ commit }, payload) {
            commit('pushToast', payload);
        },
        removeToast({ commit }, payload) {
            commit('removeToastFromStore', payload);
        },
        storeAddToast({ commit }, payload) {
            commit('pushToast', payload);
        },
        pushToast({ dispatch }, payload) {
            payload['id'] = new Date().getTime() + Math.floor(Math.random() * (100 - 1) + 1);
            dispatch('storeAddToast', payload);
            // this.storeAddToast(payload);
        },
        showSuccessToast({ dispatch }, message) {
            dispatch('pushToast', buildOptions(message, 'Успех', 'green'));
        },
        showError({ dispatch }, error) {
            let errorCode = error.message.replace(/\D+/g, '');
            let errorType = +errorCode[0];
            if (422 === +errorCode) {
                let err = error.response.data.errors;
                for (let key in err) {
                    dispatch('pushToast', buildOptions(err[key].join(', '), 'Ошибка', 'red'));
                }
            } else if (403 === +errorCode) {
                dispatch('pushToast', buildOptions(error.response.data.message, 'Ошибка', 'red'));
            } else if (5 === errorType) {
                dispatch('pushToast', buildOptions('Сервер не отвечает', 'Ошибка', 'red'));
            } else {
                dispatch('pushToast', buildOptions('Неизвестная ошибка, обратитесь в тех. поддержку', 'Ошибка', 'red'));
            }
        },
        fetchOfferDeclineStatuses({ commit }) {
            Offers.getDeclineStatuses().then((response) => {
                commit('updateOfferDeclineStatuses', response.data);
            });
        },
    },
    getters: {
        appVersion: (state) => {
            return state.packageVersion;
        },
        isLoggedIn: (state) => !!state.token,
        isProvider: (state) => {
            if (!!state.token && state.organization.is_provider) {
                return state.organization.is_provider;
            } else {
                return false;
            }
        },
        isProductionsAllowed: (state, getters) => {
            return getters.isLoggedIn && getters.isProvider && state.organization.productions_allowed;
        },
        token: (state) => state.token,
        authStatus: (state) => state.status,
        currentCertificate: (state) => state.currentCertificate,
        getPurchase: (state) => state.purchase,
        getInformation: (state) => state.informationSection,
        getUser: (state) => state.user,
        userHasCertificate: (state) => state.user.has_certificate ?? false,
        getOrganization: (state) => state.organization,
        getOrganizationHasPayments: (state) => state.organization_has_payments,
        customerCanCreateHiddenPurchase: (state) => state.organization.can_create_hidden_purchases,
        getToasts: (state) => state.toasts,
        getCanSign: (state) => !!(state.currentCertificate && state.currentCertificate.id && state.currentCertificate.serial_number),
        offerDeclineStatuses: (state) => state.offerDeclineStatuses,
        getCertificateNeedToBeProlonged: (state) => state.certificate_need_to_be_prolonged,
    },
    strict: debug,
    plugins: debug ? [createLogger(), storePlugins] : [storePlugins], // set logger only for development
    modules: {
        globalDictionaries: globalDictionariesModule,
    },
});

export default store;

<template>
    <div>
        <b-btn variant="custom-blue" class="text-nowrap text-uppercase" right v-if="!$store.state.token" @click="loginWithPassword">Вход в лк</b-btn>
        <LoginWithPasswordModal @close="closeModals" :visible="showLoginWithPassword" />
    </div>
</template>

<script>
import LoginWithPasswordModal from './login-with-password-modal.vue';

export default {
    name: 'login-button',
    components: { LoginWithPasswordModal },
    data() {
        return {
            showLoginWithPassword: false,
        };
    },
    methods: {
        loginWithRutoken() {
            this.showLoginWithPassword = false;
        },
        loginWithPassword() {
            this.showLoginWithPassword = true;
        },
        closeModals() {
            this.showLoginWithPassword = false;
        },
    },
};
</script>

<style scoped>
@media screen and (max-width: 767px) {
    .btn-custom-blue {
        background-color: var(--est-blue-2) !important;
        color: #fff !important;
        min-width: 130px;
        padding: 10px 20px !important;
        line-height: 1 !important;
        font-size: 14px !important;
    }

    .btn-custom-blue:hover {
        background-color: var(--est-blue-2) !important;
        border-color: var(--est-blue-2) !important;
    }

    .fast_auth {
        display: none !important;
    }
}

.btn-custom-blue,
.btn-custom-blue.router-link-active {
    background-color: var(--est-blue-2) !important;
    color: #fff !important;
    min-width: 130px;
    padding: 10px 20px !important;
    line-height: 1 !important;
    font-size: 14px !important;
    border-color: var(--est-blue-2) !important;
    border-bottom-width: 1px !important;
}

.btn-custom-blue:hover {
    background-color: #fff !important;
    color: var(--est-blue-2) !important;
    border-color: var(--est-blue-2) !important;
}
</style>

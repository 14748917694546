var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            centered: "",
            "hide-footer": "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
          },
          on: { hidden: _vm.hidden },
        },
        [
          _c(
            "b-form-group",
            { attrs: { description: _vm.emailFieldDescription } },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    staticClass: "fs-14",
                    attrs: {
                      state: !_vm.$v.form.email.$invalid,
                      value: _vm.form.email,
                      disabled: "",
                      placeholder: "Email пользователя",
                      type: "email",
                    },
                  }),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase fs-14",
                          staticStyle: { height: "35px" },
                          attrs: {
                            disabled: _vm.emailFieldDisabled,
                            variant: "custom-blue",
                          },
                          on: { click: _vm.sendEmailConfirmationRequest },
                        },
                        [_vm._v("Подтвердить")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("success-confirm-modal", {
            attrs: { "success-confirm": _vm.successConfirm },
            on: { hide: _vm.hideSuccessConfirm },
          }),
          _vm.showEmailConfirmationInput
            ? _c(
                "b-form-group",
                [
                  _c(
                    "b-input-group",
                    [
                      _c("the-mask", {
                        staticClass: "form-control",
                        attrs: {
                          mask: "######",
                          placeholder: "Введите код из сообщения",
                        },
                        model: {
                          value: _vm.form.emailConfirmationCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "emailConfirmationCode", $$v)
                          },
                          expression: "form.emailConfirmationCode",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: { variant: "custom-blue" },
                              on: { click: _vm.checkEmail },
                            },
                            [_vm._v("Подтвердить")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.state
            ? [
                _c("b-form-invalid-feedback", { attrs: { "force-show": "" } }, [
                  _vm._v(_vm._s(_vm.errorMessage)),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { PersonalPurchasesApiService } from './purchases';
import { PersonalUsersApiService } from './users';
import { PersonalProfileApiService } from './profile';
import { PersonalOrganizationApiService } from './organization';
import { PersonalProposalsApiService } from './proposals';
import { PersonalAccountApiService } from './account';
import { PersonalDocumentsApiService } from './documents';

export const personal = {
    purchases: new PersonalPurchasesApiService(),
    proposals: new PersonalProposalsApiService(),
    organization: new PersonalOrganizationApiService(),
    profile: new PersonalProfileApiService(),
    users: new PersonalUsersApiService(),
    account: new PersonalAccountApiService(),
    documents: new PersonalDocumentsApiService(),
};

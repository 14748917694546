var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "top_info-line", attrs: { fluid: "" } },
    [
      _c(
        "b-container",
        { staticStyle: { "max-height": "100px" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass: "w-100",
                    staticStyle: {
                      height: "36px",
                      display: "flex",
                      "align-items": "center",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between align-items-center w-100",
                      },
                      [
                        _c("div", { staticClass: "d-flex times" }, [
                          _c(
                            "div",
                            {
                              staticClass: "today",
                              staticStyle: { "margin-right": "42px" },
                            },
                            [
                              _c("span", { staticClass: "grey-color fs-12" }, [
                                _vm._v(
                                  _vm._s(_vm.kgDate) +
                                    " (" +
                                    _vm._s(_vm.weekday) +
                                    ")"
                                ),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "local_time mr-4" }, [
                            _c("img", {
                              attrs: { src: "/images/clock.svg", alt: "clock" },
                            }),
                            _c(
                              "span",
                              { staticClass: "ml-2 grey-color fs-12" },
                              [_vm._v("Бишкек — " + _vm._s(_vm.kgTime))]
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex ml-auto center-768" },
                          [
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "grey-color fs-12 fw-600 text-nowrap cursor-pointer",
                                  attrs: {
                                    href: _vm.$links.legal_documents,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("ПРАВОВЫЕ ДОКУМЕНТЫ")]
                              ),
                            ]),
                            _c("div", { staticClass: "ml-4" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "grey-color fs-12 fw-600 text-nowrap cursor-pointer",
                                  attrs: {
                                    href: _vm.$links.info_materials,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ")]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { OrgRoles } from '@/const';

export default [
    {
        path: '/cabinet',
        component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/cabinet.vue'),
        name: 'Cabinet',
        redirect: { name: 'OrganizationInformation' },
        children: [
            {
                path: 'user_data',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/user-profile.vue'),
                name: 'UserProfile',
            },
            {
                path: 'organization/users',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/users-list.vue'),
                name: 'UsersList',
            },
            {
                path: 'organization/users/create',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/user-create.vue'),
                name: 'UserCreate',
            },
            {
                path: 'organization/users/:id',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/user-edit.vue'),
                name: 'UserEdit',
            },
            {
                path: 'change_cert',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/user-change-certificate.vue'),
                name: 'UserChangeCertificate',
            },
            {
                path: 'organization/data',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/organization-information.vue'),
                name: 'OrganizationInformation',
            },
            {
                path: 'organization/documents',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/organization-documents.vue'),
                name: 'OrganizationDocuments',
            },
            {
                path: 'purchases',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/personal-purchases.vue'),
                name: 'PersonalPurchases',
                meta: { requiresAuth: true, orgRole: [OrgRoles.CUSTOMER_DIRECT, OrgRoles.CUSTOMER_SIMPLE, OrgRoles.CUSTOMER_COMPETITIVE] },
            },
            {
                path: 'proposals',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/personal-proposals.vue'),
                name: 'PersonalProposals',
            },
            {
                path: 'account/info',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/account/account-info.vue'),
                name: 'CabinetAccountInfo',
            },
            {
                path: 'account/refund_requests',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/account/account-refund.vue'),
                name: 'CabinetAccountRefundRequests',
            },
            {
                path: 'account/refund_request',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/account/cabinet-account-refund-request-create.vue'),
                name: 'CabinetAccountRefundRequestCreate',
            },
            {
                path: 'account/replenishment',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinet/account/cabinet-account-replenishment.vue'),
                name: 'CabinetAccountReplenishment',
            },
        ],
    },
];

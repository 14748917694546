var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.$store.state.token
        ? _c(
            "b-btn",
            {
              staticClass: "text-nowrap text-uppercase",
              attrs: { variant: "custom-danger" },
              on: {
                click: function ($event) {
                  _vm.showModal = true
                },
              },
            },
            [_vm._v("Регистрация")]
          )
        : _vm._e(),
      _c("register-modal", {
        attrs: { "show-modal": _vm.showModal },
        on: { close: _vm.closeModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default [
    {
        path: '/organizations',
        name: 'OrganizationsList',
        component: () => import(/* webpackChunkName: "main" */ '@/components/organizations/list.vue'),
    },
    {
        path: '/organizations/:id',
        component: () => import(/* webpackChunkName: "main" */ '@/components/organizations/show.vue'),
        name: 'OrganizationShow',
    },
];

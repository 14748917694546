import axios from 'axios';

export default {
    requestEmailConfirmation(email) {
        return axios.post('/auth/send_email_confirmation', { email: email });
    },
    checkEmailConfirmation(email, code) {
        return axios.post('/auth/check_email_confirmation', {
            email: email,
            code: code,
        });
    },
    requestPhoneConfirmation(phone) {
        return axios.post('/auth/request_phone_confirmation', { phone: phone });
    },
    checkPhoneConfirmation(phone, code) {
        return axios.post('/auth/check_phone_confirmation', {
            phone: phone,
            code: code,
        });
    },
};

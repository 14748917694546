<template>
    <b-container fluid style="background-color: #f8c8c3" v-if="isDevEnv()">
        <b-container style="max-height: 100px">
            <div class="w-100 text-center py-3" style="align-items: center">
                <strong>ВНИМАНИЕ!!! Это тестовая площадка ЕСТ</strong>
            </div>
        </b-container>
    </b-container>
</template>

<script>
export default {
    name: 'is-dev-env-bar',
};
</script>

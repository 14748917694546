<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
                <h2 class="grey-color font-weight-bold my-3">Спецификация</h2>
                <div class="text-right">
                    <b-button style="color: var(--est-blue-2) !important" variant="custom-unstyle-grey" @click="addEmptyRow"> Добавить позицию</b-button>
                </div>
            </b-col>
        </b-row>
        <b-row class="py-3" no-gutters>
            <b-col class="fs-14 grey-color">
                <div :class="v.$invalid || specErrors ? 'is-invalid' : ''" class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="1"></th>
                                <th>Наименование товара/работы/услуги</th>
                                <th>Характеристики</th>
                                <th style="min-width: 100px">Количество</th>
                                <th nowrap width="250px">Ед.изм</th>
                                <th style="min-width: 100px">Цена за ед.</th>
                                <th style="min-width: 100px">Валюта</th>
                                <th style="min-width: 100px">Стоимость</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in specs" :key="index">
                                <td class="align-top">
                                    <div style="padding: 0.375rem 0.75rem">{{ index + 1 }}</div>
                                </td>
                                <td class="align-top">
                                    <b-form-input v-model="specs[index].name" class="fs-14 my-auto" />
                                </td>
                                <td class="align-top">
                                    <b-form-textarea v-model="specs[index].characteristics" class="fs-14 my-auto" />
                                </td>
                                <td class="align-top text-right">
                                    <b-form-input v-model.number="specs[index].quantity" v-stop-number-mousewheel :class="specs[index].quantity > 0 ? '' : 'is-invalid'" class="fs-14 my-auto" min="1" step="any" type="number" />
                                </td>
                                <td class="align-top">
                                    <v-select
                                        v-model="specs[index].okei"
                                        :calculate-position="withPopper"
                                        :clearable="false"
                                        :options="okeis"
                                        :reduce="(item) => item.id"
                                        :searchable="true"
                                        append-to-body
                                        label="title"
                                        placeholder="Выберите ед. изм.">
                                        <!-- eslint-disable-next-line vue/no-unused-vars  -->
                                        <template #no-options="{ search, searching, loading }"> Записей, соответствующих вашему запросу, не найдено.</template>
                                    </v-select>
                                </td>
                                <td class="align-top">
                                    <currency-input v-model="specs[index].price_per_unit" :class="specs[index].price_per_unit > 0 ? '' : 'is-invalid'" :currency="null" class="form-control fs-14" value-as-integer />
                                </td>
                                <td class="align-top">
                                    <div style="padding: 0.375rem 0.75rem">{{ selectedCurrencyTitle }}</div>
                                </td>
                                <td class="align-top text-right">
                                    <div class="text-nowrap" style="padding: 0.375rem 0.75rem">
                                        {{ $formatPrice(Math.round((specs[index].price_per_unit ?? 0) * (specs[index].quantity ?? 0))) }}
                                    </div>
                                </td>
                                <td class="align-top" nowrap>
                                    <template v-if="!noDeleteSpec">
                                        <b-button class="mx-1" size="sm" style="background: transparent" title="Дублировать строку спецификации" variant="light" @click="cloneRow(index)">
                                            <img alt="trash_icon" src="/images/copy_icon.svg" />
                                        </b-button>
                                        <b-button class="mx-1" size="sm" style="background: transparent" title="Удалить строку спецификации" variant="light" @click="deleteSpec(index)">
                                            <img alt="trash_icon" src="/images/trash_icon.svg" />
                                        </b-button>
                                    </template>
                                </td>
                            </tr>
                            <tr v-if="specs.length === 0">
                                <td align="center" colspan="8">Не добавлено спецификаций</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p v-if="specErrors" class="text-danger">{{ specErrorMessage }}</p>
            </b-col>
        </b-row>
        <b-row no-gutters>
            <b-form-group class="w-100" label="Сумма закупки" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" label-cols-lg="3" label-cols-sm="4">
                <currency-input v-model="roundedSpecSum" v-stop-number-mousewheel :class="!specErrors ? 'is-valid' : 'is-invalid'" :currency="null" class="form-control fs-14" disabled locale="ru-KG" value-as-integer />
                <template v-if="specErrors">
                    <b-form-invalid-feedback>{{ specErrorMessage }}</b-form-invalid-feedback>
                </template>
            </b-form-group>
        </b-row>
    </div>
</template>

<script>
import { CurrencyDirective } from 'vue-currency-input';
import { createPopper } from '@popperjs/core';

export default {
    name: 'FormRowSpecificationTable',
    props: {
        value: {
            type: Array,
            required: true,
        },
        grey: {
            type: Boolean,
            default: false,
        },
        v: {
            type: Object,
            required: true,
        },
        withPrice: {
            type: Boolean,
            default: false,
        },
        withCountEdit: {
            type: Boolean,
            default: false,
        },
        specSum: {
            type: Number,
        },
        specErrors: {
            type: Boolean,
        },
        specErrorMessage: {
            type: String,
            default: '',
        },
        noAddSpec: {
            type: Boolean,
            default: false,
        },
        noDeleteSpec: {
            type: Boolean,
            default: false,
        },
        withAddDelivery: {
            type: Boolean,
            default: false,
        },
        okeis: {
            type: Array,
            default: () => [],
        },
        selectedCurrencyId: {
            type: String,
            default: null,
        },
    },
    directives: {
        currency: CurrencyDirective,
    },
    data() {
        return {
            spec: {
                name: '',
                characteristics: '',
                quantity: 1,
                price_per_unit: 0,
                okei: null,
            },
            showSpec: false,
            tmpSpecs: [],
            visible: [],
        };
    },
    methods: {
        addEmptyRow() {
            const spec = {
                name: '',
                characteristics: '',
                quantity: 1,
                price_per_unit: 0,
                okei: null,
            };
            this.addSpec(spec);
        },
        cloneRow(index) {
            this.tmpSpecs = this.specs;
            this.showSpec = false;
            let spec = this.specs[index];
            let newSpec = JSON.parse(JSON.stringify(spec)); // clone
            // const insertIndex =
            this.tmpSpecs.splice(index + 1, 0, newSpec);
            this.specs = this.tmpSpecs;
        },
        addSpec(spec) {
            this.tmpSpecs = this.specs;
            this.showSpec = false;
            let newSpec = JSON.parse(JSON.stringify(spec)); // clone
            if (this.withPrice) {
                newSpec['price'] = 0;
            }
            this.tmpSpecs.push(newSpec);
            this.specs = this.tmpSpecs;
        },
        removeSpec(index) {
            this.tmpSpecs = this.specs;
            this.tmpSpecs.splice(index, 1);
            let startIndex = 1;
            this.tmpSpecs.forEach((item) => {
                item.number = startIndex;
                item.order = startIndex;
                startIndex++;
            });
            this.specs = this.tmpSpecs;
        },
        deleteSpec(index) {
            const message = 'Вы действительно хотите удалить строку № ' + (index + 1) + ' ?';
            this.$bvModal
                .msgBoxConfirm(message, {
                    title: 'Удаление строки спецификации',
                    okTitle: 'УДАЛИТЬ',
                    okVariant: 'custom-danger',
                    cancelTitle: 'ОТМЕНА',
                    cancelVariant: 'custom-outline-secondary',
                    centered: true,
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                })
                .then(async (value) => {
                    if (value) {
                        this.removeSpec(index);
                    }
                });
        },
        withPopper(dropdownList, component, { width }) {
            dropdownList.style.width = width;
            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: 'bottom',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1],
                        },
                    },
                ],
            });

            return () => popper.destroy();
        },
    },
    computed: {
        specs: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        roundedSpecSum() {
            return Math.round(this.specSum * 100) / 100;
        },
        selectedCurrency() {
            return this.selectedCurrencyId ? this.$globalDictionaries.currencies.find((currency) => currency.id === this.selectedCurrencyId) : null;
        },
        selectedCurrencyTitle() {
            return this.selectedCurrency ? this.selectedCurrency.title : '';
        },
    },
};
</script>

<style scoped>
.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>

import axios from 'axios';

export default {
    getPurchaseOfferXml(purchaseId, offer) {
        return axios.post(`/purchases/${purchaseId}/offers/offer_xml`, { offer });
    },
    getXml(payload) {
        return axios.post('/xml/offer_form', { offer: payload });
    },
    decline(id, formData) {
        return axios.post('/offer/' + id + '/decline', formData);
    },
    getDeclineStatuses() {
        return axios.get('/offer/get_decline_statuses');
    },
};

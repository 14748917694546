<template>
    <h2 :class="headerClass + ' ' + marginClass">
        <slot></slot>
    </h2>
</template>

<script>
export default {
    name: 'text-header',
    props: {
        headerClass: {
            type: String,
            default: 'grey-color font-weight-bold',
        },
        marginClass: {
            type: String,
            default: 'my-3',
        },
    },
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>

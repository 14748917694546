import { DateTime } from 'luxon';

export default {
    methods: {
        getDateFormat(data) {
            return DateTime.fromISO(data).toFormat('dd.LL.yyyy');
        },
        getDateTimeFormat(data) {
            return DateTime.fromISO(data).setZone('Asia/Bishkek').setLocale('ru').toFormat('dd.LL.yyyy HH:mm');
        },
        getDateTimeFormatSeconds(data) {
            return DateTime.fromISO(data).setZone('Asia/Bishkek').setLocale('ru').toFormat('dd.LL.yyyy HH:mm:ss');
        },
        num2str(n, text_forms) {
            n = Math.abs(n) % 100;
            const n1 = n % 10;
            if (n > 10 && n < 20) {
                return text_forms[2];
            }
            if (n1 > 1 && n1 < 5) {
                return text_forms[1];
            }
            if (n1 === 1) {
                return text_forms[0];
            }
            return text_forms[2];
        },
    },
};

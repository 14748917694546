var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isDevEnv()
    ? _c(
        "b-container",
        {
          staticStyle: { "background-color": "#f8c8c3" },
          attrs: { fluid: "" },
        },
        [
          _c("b-container", { staticStyle: { "max-height": "100px" } }, [
            _c(
              "div",
              {
                staticClass: "w-100 text-center py-3",
                staticStyle: { "align-items": "center" },
              },
              [_c("strong", [_vm._v("ВНИМАНИЕ!!! Это тестовая площадка ЕСТ")])]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
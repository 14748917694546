var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.$store.state.token
        ? _c(
            "b-btn",
            {
              staticClass: "text-nowrap text-uppercase",
              attrs: { variant: "custom-blue", right: "" },
              on: { click: _vm.loginWithPassword },
            },
            [_vm._v("Вход в лк")]
          )
        : _vm._e(),
      _c("LoginWithPasswordModal", {
        attrs: { visible: _vm.showLoginWithPassword },
        on: { close: _vm.closeModals },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { BaseApiService } from '@/services/backend/base';
import axios from 'axios';

export class SimpleProposalsApiService extends BaseApiService {
    constructor() {
        super('proposals/simple');
    }

    async get(id) {
        try {
            const response = await axios.get(this.getUrl(id));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

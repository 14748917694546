var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "label-cols-sm": "4",
        "label-cols-lg": "3",
        description: _vm.description,
        label: _vm.label + ":",
        "label-for": _vm.id,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _c(
        "p",
        { staticClass: "fs-14 grey-color my-auto col-form-label" },
        [
          _vm.routerLink
            ? [
                _c(
                  "router-link",
                  {
                    staticClass: "green-color",
                    attrs: { to: _vm.routerLink, tag: "a", target: "_blank" },
                  },
                  [_vm._v(_vm._s(_vm.value))]
                ),
              ]
            : [_vm._v(" " + _vm._s(_vm.value) + " ")],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
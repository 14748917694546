import { BaseApiService } from '@/services/backend/base';
import axios from 'axios';

export class PersonalDocumentsApiService extends BaseApiService {
    constructor() {
        super('personal/documents');
    }

    async getList() {
        try {
            const response = await axios.get(this.getUrl());
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async store(data) {
        try {
            const response = await axios.post(this.getUrl(), data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete(this.getUrl(id));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

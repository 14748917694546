var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "label-cols-sm": "4",
        "label-cols-lg": "3",
        description: _vm.description,
        label: _vm.label + ":",
        "label-for": _vm.id,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _c(
        "ul",
        { attrs: { id: _vm.id } },
        _vm._l(_vm.value, function (address, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "fs-14 grey-color my-auto col-form-label",
            },
            [_vm._v(" " + _vm._s(address.address) + " ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
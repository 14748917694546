import { OrgRoles } from '@/const';

export default [
    {
        path: '/purchases/competitive/create',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/competitive/create'),
        name: 'CompetitivePurchaseCreate',
        meta: { requiresAuth: true, orgRole: OrgRoles.CUSTOMER_COMPETITIVE },
    },
    {
        path: '/purchases/competitive/:id/edit',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/competitive/create'),
        name: 'CompetitivePurchaseEdit',
        meta: { requiresAuth: true, orgRole: OrgRoles.CUSTOMER_COMPETITIVE },
    },
    {
        path: '/purchases/competitive/:id/show',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/competitive/show'),
        name: 'CompetitivePurchaseShow',
    },
    {
        path: '/purchases/competitive/:id/new_proposal',
        name: 'CompetitivePurchaseNewProposal',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/competitive/proposal-create.vue'),
        meta: { requiresAuth: true, orgRole: OrgRoles.SUPPLIER_COMPETITIVE },
    },

    {
        path: '/purchases/direct/:id/edit',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/direct/create'),
        name: 'DirectPurchaseEdit',
        meta: { requiresAuth: true, orgRole: OrgRoles.CUSTOMER_DIRECT },
    },
    {
        path: '/purchases/direct/:id/show',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/direct/show'),
        name: 'DirectPurchaseShow',
    },
    {
        path: '/purchases/direct/:id/new_proposal',
        name: 'DirectPurchaseNewProposal',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/direct/proposal-create.vue'),
        meta: { requiresAuth: true, orgRole: OrgRoles.SUPPLIER_DIRECT },
    },

    {
        path: '/purchases/simple/create',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/simple/create'),
        name: 'SimplePurchaseCreate',
        meta: { requiresAuth: true, orgRole: OrgRoles.CUSTOMER_SIMPLE },
    },
    {
        path: '/purchases/simple/:id/edit',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/simple/create'),
        name: 'SimplePurchaseEdit',
        meta: { requiresAuth: true, orgRole: OrgRoles.CUSTOMER_SIMPLE },
    },
    {
        path: '/purchases/simple/:id/show',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/simple/show'),
        name: 'SimplePurchaseShow',
    },
    {
        path: '/purchases/simple/:id/new_proposal',
        name: 'SimplePurchaseNewProposal',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/simple/proposal-create.vue'),
        meta: { requiresAuth: true, orgRole: OrgRoles.SUPPLIER_SIMPLE },
    },

    {
        path: '/notices',
        component: () => import(/* webpackChunkName: "main" */ '@/components/notices/list'),
        name: 'NoticesList',
    },
];

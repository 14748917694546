<template>
    <b-modal v-if="!$store.getters.isLoggedIn" ref="auth-modal" title="Регистрация" :visible="showModal" hide-footer @close="close" centered>
        <div class="d-block text-center w-100 m-auto">
            <h4>Уважаемый пользователь!</h4>
        </div>
        <div class="d-block text-justify w-100 mb-4">
            <p class="grey-color">
                Прохождение процедуры регистрации означает согласие с
                <a :href="$links.user_agreement" target="_blank" style="color: var(--est-blue-2) !important; text-decoration: underline !important">Пользовательским соглашением</a>,
                <a :href="$links.privacy_policy" target="_blank" style="color: var(--est-blue-2) !important; text-decoration: underline !important">Политикой конфиденциальности</a>
                и ознакомление с
                <a :href="$links.user_guide" target="_blank" style="color: var(--est-blue-2) !important; text-decoration: underline !important">Руководством пользователя</a>.
            </p>
        </div>
        <div class="d-block text-center w-100 mb-5">
            <b-form-group :state="isCheckRules">
                <b-form-checkbox v-model="readedDocumetation" :state="isCheckRules">
                    <span class="grey-color fw-600">Подтверждаю ознакомление с материалами</span>
                </b-form-checkbox>
            </b-form-group>
        </div>
        <div class="d-block text-center w-75 m-auto">
            <b-link to="/auth/register" class="btn btn-block btn-custom-blue mb-4 text-uppercase" @click="close" :disabled="!readedDocumetation"> Регистрация </b-link>
        </div>
        <div class="mt-5">
            <p class="m-0 fs-12 grey-color text-center">Уже зарегистрированы? Используйте кнопку "Вход в ЛК"</p>
        </div>
        <div class="mt-3">
            <p class="m-0 fs-12 grey-color text-center">
                <a class="grey-color" :href="$links.workbench_setup" target="_blank" style="color: var(--est-blue-2) !important; text-decoration: underline !important">Инструкция по настройке рабочего места и началу работы</a>
            </p>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'register-modal',
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            readedDocumetation: false,
        };
    },
    mounted() {
        this.readedDocumetation = false;
    },
    methods: {
        close() {
            this.readedDocumetation = false;
            this.$emit('close');
        },
    },
    computed: {
        isCheckRules() {
            return this.readedDocumetation;
        },
    },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "p",
              { staticClass: "mt-3 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: { to: { name: "NoticesList" } },
                  },
                  [_vm._v("Объявления о закупках")]
                ),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "mt-3 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                    attrs: { to: { name: "OrganizationsList" } },
                  },
                  [_vm._v("Организации ")]
                ),
              ],
              1
            ),
            _c("p", { staticClass: "mt-3 text-center" }, [
              _c(
                "a",
                {
                  staticClass:
                    "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                  attrs: { href: _vm.$links.info_materials, target: "_blank" },
                },
                [_vm._v(" ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ ")]
              ),
            ]),
            _c("p", { staticClass: "mt-3 text-center" }, [
              _c(
                "a",
                {
                  staticClass:
                    "text-uppercase fw-600 grey-color text-decoration-none fs-14",
                  attrs: { href: _vm.$links.legal_documents, target: "_blank" },
                },
                [_vm._v(" Правовые документы ")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "d-flex flex-column align-items-center" },
              [
                _c("register-button", { staticClass: "my-1" }),
                !!_vm.$store.state.token
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "text-uppercase text-nowrap btn btn-custom-blue my-1",
                        attrs: {
                          tag: "button",
                          exact: "",
                          to: { name: "Cabinet" },
                        },
                      },
                      [_vm._v("Личный кабинет ")]
                    )
                  : _vm._e(),
                _c("login-button", { staticClass: "my-1" }),
                !!_vm.$store.state.token
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "text-uppercase text-nowrap btn btn-custom-outline-secondary my-1",
                        attrs: { tag: "button", to: "/auth/logout" },
                      },
                      [_vm._v(" Выйти ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c("h2", { staticClass: "grey-color font-weight-bold my-3" }, [
                _vm._v("Спецификация"),
              ]),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticStyle: { color: "var(--est-blue-2) !important" },
                      attrs: { variant: "custom-unstyle-grey" },
                      on: { click: _vm.addEmptyRow },
                    },
                    [_vm._v(" Добавить позицию")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "py-3", attrs: { "no-gutters": "" } },
        [
          _c("b-col", { staticClass: "fs-14 grey-color" }, [
            _c(
              "div",
              {
                staticClass: "table-responsive",
                class: _vm.v.$invalid || _vm.specErrors ? "is-invalid" : "",
              },
              [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { width: "1" } }),
                      _c("th", [_vm._v("Наименование товара/работы/услуги")]),
                      _c("th", [_vm._v("Характеристики")]),
                      _c("th", { staticStyle: { "min-width": "100px" } }, [
                        _vm._v("Количество"),
                      ]),
                      _c("th", { attrs: { nowrap: "", width: "250px" } }, [
                        _vm._v("Ед.изм"),
                      ]),
                      _c("th", { staticStyle: { "min-width": "100px" } }, [
                        _vm._v("Цена за ед."),
                      ]),
                      _c("th", { staticStyle: { "min-width": "100px" } }, [
                        _vm._v("Валюта"),
                      ]),
                      _c("th", { staticStyle: { "min-width": "100px" } }, [
                        _vm._v("Стоимость"),
                      ]),
                      _c("th", { attrs: { width: "1" } }),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.specs, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", { staticClass: "align-top" }, [
                            _c(
                              "div",
                              { staticStyle: { padding: "0.375rem 0.75rem" } },
                              [_vm._v(_vm._s(index + 1))]
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "align-top" },
                            [
                              _c("b-form-input", {
                                staticClass: "fs-14 my-auto",
                                model: {
                                  value: _vm.specs[index].name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.specs[index], "name", $$v)
                                  },
                                  expression: "specs[index].name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "align-top" },
                            [
                              _c("b-form-textarea", {
                                staticClass: "fs-14 my-auto",
                                model: {
                                  value: _vm.specs[index].characteristics,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.specs[index],
                                      "characteristics",
                                      $$v
                                    )
                                  },
                                  expression: "specs[index].characteristics",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "align-top text-right" },
                            [
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "stop-number-mousewheel",
                                    rawName: "v-stop-number-mousewheel",
                                  },
                                ],
                                staticClass: "fs-14 my-auto",
                                class:
                                  _vm.specs[index].quantity > 0
                                    ? ""
                                    : "is-invalid",
                                attrs: {
                                  min: "1",
                                  step: "any",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.specs[index].quantity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.specs[index],
                                      "quantity",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "specs[index].quantity",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "align-top" },
                            [
                              _c("v-select", {
                                attrs: {
                                  "calculate-position": _vm.withPopper,
                                  clearable: false,
                                  options: _vm.okeis,
                                  reduce: (item) => item.id,
                                  searchable: true,
                                  "append-to-body": "",
                                  label: "title",
                                  placeholder: "Выберите ед. изм.",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "no-options",
                                      fn: function ({
                                        search,
                                        searching,
                                        loading,
                                      }) {
                                        return [
                                          _vm._v(
                                            " Записей, соответствующих вашему запросу, не найдено."
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.specs[index].okei,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.specs[index], "okei", $$v)
                                  },
                                  expression: "specs[index].okei",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "align-top" },
                            [
                              _c("currency-input", {
                                staticClass: "form-control fs-14",
                                class:
                                  _vm.specs[index].price_per_unit > 0
                                    ? ""
                                    : "is-invalid",
                                attrs: {
                                  currency: null,
                                  "value-as-integer": "",
                                },
                                model: {
                                  value: _vm.specs[index].price_per_unit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.specs[index],
                                      "price_per_unit",
                                      $$v
                                    )
                                  },
                                  expression: "specs[index].price_per_unit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("td", { staticClass: "align-top" }, [
                            _c(
                              "div",
                              { staticStyle: { padding: "0.375rem 0.75rem" } },
                              [_vm._v(_vm._s(_vm.selectedCurrencyTitle))]
                            ),
                          ]),
                          _c("td", { staticClass: "align-top text-right" }, [
                            _c(
                              "div",
                              {
                                staticClass: "text-nowrap",
                                staticStyle: { padding: "0.375rem 0.75rem" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$formatPrice(
                                        Math.round(
                                          (_vm.specs[index].price_per_unit ??
                                            0) *
                                            (_vm.specs[index].quantity ?? 0)
                                        )
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "td",
                            { staticClass: "align-top", attrs: { nowrap: "" } },
                            [
                              !_vm.noDeleteSpec
                                ? [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mx-1",
                                        staticStyle: {
                                          background: "transparent",
                                        },
                                        attrs: {
                                          size: "sm",
                                          title:
                                            "Дублировать строку спецификации",
                                          variant: "light",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cloneRow(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            alt: "trash_icon",
                                            src: "/images/copy_icon.svg",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mx-1",
                                        staticStyle: {
                                          background: "transparent",
                                        },
                                        attrs: {
                                          size: "sm",
                                          title: "Удалить строку спецификации",
                                          variant: "light",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteSpec(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            alt: "trash_icon",
                                            src: "/images/trash_icon.svg",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      }),
                      _vm.specs.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              { attrs: { align: "center", colspan: "8" } },
                              [_vm._v("Не добавлено спецификаций")]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _vm.specErrors
              ? _c("p", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.specErrorMessage)),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "b-form-group",
            {
              staticClass: "w-100",
              attrs: {
                label: "Сумма закупки",
                "label-class":
                  "fs-14 grey-color fw-700 d-flex align-items-baseline",
                "label-cols-lg": "3",
                "label-cols-sm": "4",
              },
            },
            [
              _c("currency-input", {
                directives: [
                  {
                    name: "stop-number-mousewheel",
                    rawName: "v-stop-number-mousewheel",
                  },
                ],
                staticClass: "form-control fs-14",
                class: !_vm.specErrors ? "is-valid" : "is-invalid",
                attrs: {
                  currency: null,
                  disabled: "",
                  locale: "ru-KG",
                  "value-as-integer": "",
                },
                model: {
                  value: _vm.roundedSpecSum,
                  callback: function ($$v) {
                    _vm.roundedSpecSum = $$v
                  },
                  expression: "roundedSpecSum",
                },
              }),
              _vm.specErrors
                ? [
                    _c("b-form-invalid-feedback", [
                      _vm._v(_vm._s(_vm.specErrorMessage)),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "footer", attrs: { fluid: "" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column align-items-start" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: { to: { name: "NoticesList" } },
                      },
                      [_vm._v(" Объявления о закупках ")]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: { to: { name: "OrganizationsList" } },
                      },
                      [_vm._v(" Организации ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column align-items-start" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: {
                          href: _vm.links.info_materials,
                          target: "_blank",
                        },
                      },
                      [_vm._v(" ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ ")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass:
                          "fs-10 white-color text-uppercase text-decoration-none my-1",
                        attrs: {
                          href: _vm.links.legal_documents,
                          target: "_blank",
                        },
                      },
                      [_vm._v("Правовые документы")]
                    ),
                  ]
                ),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "12", sm: "auto" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "flex-row-568" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex flex-column align-items-start mt-568",
                          attrs: { cols: "6", md: "12" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "fs-10 white-color text-uppercase text-decoration-none my-1",
                            },
                            [_vm._v("Режим работы - Круглосуточно")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "fs-10 white-color text-decoration-none my-2 py-2",
                  attrs: { md: "12" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.appVersion) + ' © ОсОО "ЭК-Система" 2023 '
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <b-container fluid class="footer">
        <b-container>
            <b-row>
                <b-col>
                    <div class="d-flex flex-column align-items-start">
                        <router-link :to="{ name: 'NoticesList' }" class="fs-10 white-color text-uppercase text-decoration-none my-1"> Объявления о закупках </router-link>
                        <router-link :to="{ name: 'OrganizationsList' }" class="fs-10 white-color text-uppercase text-decoration-none my-1"> Организации </router-link>
                    </div>
                </b-col>
                <b-col>
                    <div class="d-flex flex-column align-items-start">
                        <a :href="links.info_materials" target="_blank" class="fs-10 white-color text-uppercase text-decoration-none my-1"> ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ </a>
                        <a :href="links.legal_documents" target="_blank" class="fs-10 white-color text-uppercase text-decoration-none my-1">Правовые документы</a>
                    </div>
                </b-col>
                <b-col cols="12" sm="auto">
                    <b-row class="flex-row-568">
                        <b-col cols="6" md="12" class="d-flex flex-column align-items-start mt-568">
                            <p class="fs-10 white-color text-uppercase text-decoration-none my-1">Режим работы - Круглосуточно</p>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="fs-10 white-color text-decoration-none my-2 py-2"> {{ appVersion }} &copy; ОсОО "ЭК-Система" 2023 </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<style scoped>
.footer {
    background-color: #959ba4;
    padding-top: 26px;
    padding-bottom: 26px;
}

.mt-568 {
    margin-top: 0px;
}
.feed_footer:hover {
    color: var(--est-blue-2);
}

@media screen and (max-width: 568px) {
    .footer {
        padding-top: 22px;
    }

    a,
    p,
    span {
        font-size: 8px !important;
    }

    a,
    p,
    span {
        margin: 6px 0 !important;
    }

    .mt-568 {
        margin-top: 12px;
    }

    .flex-row-568 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}
</style>

<script>
import { mapGetters } from 'vuex';
import { __LINKS } from '@/const';

export default {
    name: 'LayoutFooter',
    computed: {
        ...mapGetters(['appVersion']),
        links() {
            return __LINKS;
        },
    },
};
</script>

import axios from 'axios';
import { BaseApiService } from '@/services/backend/base';
import { personal } from '@/services/backend/personal';
import { purchases } from '@/services/backend/purchases';
import { proposals } from '@/services/backend/proposals';

class NoticesApiService extends BaseApiService {
    constructor() {
        super('purchases');
    }

    async list(filters) {
        try {
            const response = await axios.get(this.getUrl(), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

class OrganizationsApiService extends BaseApiService {
    constructor() {
        super('organizations');
    }

    async list(filters) {
        try {
            const response = await axios.get(this.getUrl(), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async get(id) {
        try {
            const response = await axios.get(this.getUrl(id));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

class XMLGeneratorApiService extends BaseApiService {
    constructor() {
        super('xml/generate');
    }

    async register(form) {
        try {
            const response = await axios.post(this.getUrl('register'), form);
            return response.data?.xml;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async competitiveNotice(id) {
        try {
            const response = await axios.post(this.getUrl('notice_competitive/' + id));
            return response.data?.xml;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async competitiveProposal(id, form) {
        try {
            const response = await axios.post(this.getUrl('proposal_competitive/' + id), { proposal: form });
            return response.data?.xml;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async directNotice(id) {
        try {
            const response = await axios.post(this.getUrl('notice_direct/' + id));
            return response.data?.xml;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async directProposal(id, form) {
        try {
            const response = await axios.post(this.getUrl('proposal_direct/' + id), { proposal: form });
            return response.data?.xml;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async simpleNotice(id) {
        try {
            const response = await axios.post(this.getUrl('notice_simple/' + id));
            return response.data?.xml;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async simpleProposal(id, form) {
        try {
            const response = await axios.post(this.getUrl('proposal_simple/' + id), { proposal: form });
            return response.data?.xml;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

class PDFGeneratorApiService extends BaseApiService {
    constructor() {
        super('pdf/generate');
    }

    async simpleProposal(id, form) {
        try {
            const response = await axios.post(this.getUrl('proposal_simple/' + id), { proposal: form });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async competitiveProposal(id, form) {
        try {
            const response = await axios.post(this.getUrl('proposal_competitive/' + id), { proposal: form });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async directProposal(id, form) {
        try {
            const response = await axios.post(this.getUrl('proposal_direct/' + id), { proposal: form });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

export const $api = {
    notices: new NoticesApiService(),
    organizations: new OrganizationsApiService(),
    purchases: purchases,
    personal: personal,
    proposals: proposals,
    xmlGenerator: new XMLGeneratorApiService(),
    pdfGenerator: new PDFGeneratorApiService(),
};

<template>
    <div>
        <b-btn variant="custom-danger" class="text-nowrap text-uppercase" v-if="!$store.state.token" @click="showModal = true">Регистрация</b-btn>
        <register-modal :show-modal="showModal" @close="closeModal" />
    </div>
</template>

<script>
import RegisterModal from './register-modal.vue';

export default {
    name: 'register-button',
    components: {
        RegisterModal,
    },
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
        closeModal() {
            this.showModal = false;
        },
    },
};
</script>

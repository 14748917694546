<template>
    <div v-if="certificate">
        <h6 class="grey-color font-weight-bold my-3">Информация о выбранном сертификате</h6>
        <div class="fs-14 my-3">
            <b-row>
                <b-col v-if="certificate.subject.inn" class="py-1" md="6">
                    <strong>ИНН</strong><br />
                    {{ certificate.subject.inn }}
                </b-col>
                <b-col v-if="certificate.subject.organization_name" class="py-1" md="6">
                    <strong>Организация</strong><br />
                    {{ certificate.subject.organization_name }}
                </b-col>
                <b-col v-if="certificate.subject.full_name" class="py-1" md="6">
                    <strong>ФИО</strong><br />
                    {{ certificate.subject.full_name }}
                </b-col>
                <b-col v-if="certificate.subject.position" class="py-1" md="6">
                    <strong>Должность</strong><br />
                    {{ certificate.subject.position }}
                </b-col>
            </b-row>
            <b-row>
                <b-col class="py-1">
                    <strong>SHA1 отпечаток</strong><br />
                    {{ certificate.prepared_thumbprint }}
                </b-col>
            </b-row>
            <b-row>
                <b-col class="py-1">
                    <strong>Серийный номер</strong><br />
                    {{ certificate.prepared_serial }}
                </b-col>
            </b-row>
            <b-row>
                <b-col class="py-1">
                    <strong>Срок действия</strong><br />
                    С {{ getDateFormat(certificate.valid.from) }} по {{ getDateFormat(certificate.valid.to) }}
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'selected-certificate-information',
    props: ['certificate'],
};
</script>

<style scoped></style>

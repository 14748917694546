<template>
    <b-form-group :label-cols-sm="labelColsSm" :label-cols-lg="labelColsLg" :description="description" :label="label + ':'" :label-for="id" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <p v-for="document of value" :key="document.id" class="fs-14 grey-color my-auto col-form-label">
            <a :href="$getAttachmentDownloadLink(document.id)">{{ document.name }}</a>
        </p>
    </b-form-group>
</template>

<script>
export default {
    name: 'text-row-documents',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Array,
            default: () => [],
        },
        description: {
            type: String,
            default: '',
        },
        labelColsSm: {
            type: Number,
            default: 4,
        },
        labelColsLg: {
            type: Number,
            default: 3,
        },
    },
    computed: {
        id() {
            return 'text_row_documents_' + this._uid;
        },
    },
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>

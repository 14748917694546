<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" :label="label + ':'" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <v-date-picker class="inline-block h-full" v-model="date" mode="dateTime" is24hr timezone="Asia/Dhaka" :min-date="minDate" :max-date="maxDate" :minute-increment="5">
            <template v-slot="{ inputValue, togglePopover }">
                <b-form-input :id="id" :value="inputValue" :state="state" readonly class="fs-14" :class="!disabled ? 'bg-white' : ''" @click="!disabled ? togglePopover() : null" :disabled="disabled"></b-form-input>
                <template v-if="!state">
                    <b-form-invalid-feedback>{{ errorMessage }}</b-form-invalid-feedback>
                </template>
            </template>
        </v-date-picker>
    </b-form-group>
</template>

<script>
import { DateTime } from 'luxon';

export default {
    name: 'form-row-edit-datetime-2',
    props: {
        value: {
            default: null,
        },
        v: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            required: true,
        },
        minDate: {
            default: null,
        },
        maxDate: {
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: 'Выберите дату/время',
        },
        withoutNow: {
            type: Boolean,
            default: false,
        },
        minErrorText: {
            type: String,
            default: '',
        },
        maxErrorText: {
            type: String,
            default: '',
        },
    },
    created() {},
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                if (value) {
                    this.$emit('input', DateTime.fromJSDate(value).set({ second: 0, millisecond: 0 }).toJSDate());
                } else {
                    this.$emit('input', value);
                }
            },
        },
        id() {
            return 'form_row_edit_datetime_2_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        stateClass() {
            if (!this.v) {
                return '';
            } else {
                return this.v.$invalid ? 'is-invalid' : 'is-valid';
            }
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required) return 'Поле обязательно для заполнения';
                if (!this.v.minValue) return this.minErrorText;
                if (!this.v.maxValue) return this.maxErrorText;
            }
            return '';
        },
    },
};
</script>

import { BaseApiService } from '@/services/backend/base';
import axios from 'axios';
import store from '@/store';

export class CompetitivePurchasesApiService extends BaseApiService {
    constructor() {
        super('purchases/competitive');
    }

    async create() {
        try {
            const response = await axios.get(this.getUrl('create'));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async show(id) {
        try {
            const response = await axios.get(this.getUrl(id));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getDraft(id) {
        try {
            const response = await axios.get(this.getUrl(id) + '/edit');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async createDraft(draftData) {
        try {
            const response = await axios.post(this.getUrl('create'), draftData);
            await store.dispatch('showSuccessToast', 'Черновик успешно сохранен');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async updateDraft(id, draftData) {
        try {
            const response = await axios.post(this.getUrl(id) + '/edit', draftData);
            await store.dispatch('showSuccessToast', 'Черновик успешно сохранен');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async updateNote(id, note) {
        try {
            const response = await axios.put(this.getUrl(id) + '/note', { note: note });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeSigned(id, purchaseData, xml, signature, certificate) {
        try {
            const response = await axios.post(this.getUrl(id) + '/save', {
                purchase: purchaseData,
                xml: xml,
                signature: signature,
                certificate: certificate,
            });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeSignedProposal(id, proposalData, xml, signature, certificate) {
        try {
            const response = await axios.post(this.getUrl(id) + '/proposals/save', {
                proposal: proposalData,
                xml: xml,
                signature: signature,
                certificate: certificate,
            });
            await store.dispatch('showSuccessToast', 'Заявка успешно подана');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeSignedByPdfProposal(id, proposalData, attachment_id) {
        try {
            const response = await axios.post(this.getUrl(id) + '/proposals/save', {
                proposal: proposalData,
                pdf_signed_attachment_id: attachment_id,
            });
            await store.dispatch('showSuccessToast', 'Заявка успешно подана');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async setWinnerProposal(purchaseId, proposalId) {
        try {
            const response = await axios.post(this.getUrl(purchaseId) + '/proposals/' + proposalId + '/accept');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async rejectProposal(purchaseId, proposalId, data) {
        try {
            const response = await axios.post(this.getUrl(purchaseId) + '/proposals/' + proposalId + '/reject', data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async publish(id) {
        try {
            const response = await axios.post(this.getUrl(id) + '/publish');
            await store.dispatch('showSuccessToast', 'Успешно опубликовано');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async delete(id) {
        try {
            const response = await axios.delete(this.getUrl(id) + '/delete');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async cancel(id, data) {
        try {
            const response = await axios.post(this.getUrl(id) + '/cancel', data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async copy(id) {
        try {
            const response = await axios.post(this.getUrl(id) + '/copy');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async directPurchase(id) {
        try {
            const response = await axios.post(this.getUrl(id) + '/direct_purchase');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeEnvelopesOpening(id, data) {
        try {
            const response = await axios.post(this.getUrl(id) + '/envelopes_opening/store', data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async confirmEnvelopesOpening(id, data) {
        try {
            const response = await axios.post(this.getUrl(id) + '/envelopes_opening/confirm', data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async startWinnerSelection(id, data) {
        try {
            const response = await axios.post(this.getUrl(id) + '/winner_selection/start', data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeWinnerSelection(id, data) {
        try {
            const response = await axios.post(this.getUrl(id) + '/winner_selection/store', data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async confirmWinnerSelection(id, data) {
        try {
            const response = await axios.post(this.getUrl(id) + '/winner_selection/confirm', data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getOfferCreateData(id) {
        try {
            const response = await axios.get(this.getUrl(id) + '/proposals/retrieve');
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

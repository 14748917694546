import axios from 'axios';
import { BaseApiService } from '@/services/backend/base';

export class PersonalPurchasesApiService extends BaseApiService {
    constructor() {
        super('personal/purchases');
    }

    async getList(filters) {
        try {
            const response = await axios.get(this.getUrl(), { params: filters });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

import { BaseApiService } from '@/services/backend/base';
import axios from 'axios';

export class PersonalAccountApiService extends BaseApiService {
    constructor() {
        super('personal/account');
    }

    async getBrief() {
        try {
            const response = await axios.get(this.getUrl('brief'));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getTransactions(params) {
        try {
            const response = await axios.get(this.getUrl('transactions'), { params });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getRefundRequests(params) {
        try {
            const response = await axios.get(this.getUrl('refund_requests'), { params });
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getAvailableFunds() {
        try {
            const response = await axios.get(this.getUrl('refund_requests/create'));
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async storeRefundRequest(data) {
        try {
            const response = await axios.post(this.getUrl('refund_requests'), data);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async downloadInvoice(data) {
        try {
            const response = await axios.post(this.getUrl('replenish'), data, { responseType: 'blob' });

            const contentDisposition = response.headers.get('Content-Disposition') || '';
            let filename = contentDisposition.split('filename=')[1];
            filename = filename.replace(/"/g, '');

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}

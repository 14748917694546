<template>
    <b-form-group :label-cols-sm="labelColsSm" :label-cols-lg="labelColsLg" :description="description" :label="label + ':'" :label-for="id" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <p :id="id" class="fs-14 grey-color my-auto col-form-label">
            <a :href="$getAttachmentDownloadLink(value.id)">{{ value.name }}</a>
        </p>
    </b-form-group>
</template>

<script>
export default {
    name: 'text-row-document',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            default: null,
        },
        description: {
            type: String,
            default: '',
        },
        labelColsSm: {
            type: Number,
            default: 4,
        },
        labelColsLg: {
            type: Number,
            default: 3,
        },
    },
    computed: {
        id() {
            return 'text_row_document_' + this._uid;
        },
    },
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>

<template>
    <div>
        <b-form-group :label-cols-sm="labelColsSm" :label-cols-lg="labelColsLg" :label="title + ':'" class="mb-0" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
            <template v-if="!!document">
                <a class="fs-14 col-form-label d-inline-block" :href="$getAttachmentDownloadLink(document.id)">{{ document.name }}</a>
                <b-button @click="removeFile(0, true)" variant="light" size="sm" class="float-right" style="background: transparent" :disabled="disabled">
                    <img src="/images/trash_icon.svg" alt="trash_icon" />
                </b-button>
            </template>
            <template v-else>
                <p class="col-form-label text-danger d-inline-block" style="font-size: 80%; line-height: 23px" v-if="!state">Прикрепите документ</p>
                <file-uploader :is-main="true" :is-disabled="!!document || disabled" @set_file="addFile" class="d-inline-block float-right">Загрузить</file-uploader>
            </template>
        </b-form-group>
    </div>
</template>

<script>
export default {
    name: 'FormRowSingleLineDocumentUpload',
    props: {
        title: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
        },
        v: {
            type: Object,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        headerClass: {
            type: String,
            default: 'fs-32',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        labelColsSm: {
            type: Number,
            default: 4,
        },
        labelColsLg: {
            type: Number,
            default: 3,
        },
    },
    data() {
        return {
            visible: [],
        };
    },
    methods: {
        addFile(file) {
            this.document = file;
        },
        removeFile() {
            this.document = null;
        },
    },
    computed: {
        document: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
    },
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    vertical-align: middle;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}

a {
    color: var(--est-blue-2) !important;
}
</style>

<template>
    <b-form-group :description="description" :label="label + ':'" :label-cols-lg="labelColsLg" :label-cols-sm="labelColsSm" :label-for="id" :state="state" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <b-form-checkbox :id="id" v-model="text" :class="align" :disabled="disabled" :state="state" class="fs-14 grey-color mt-2"></b-form-checkbox>
        <template v-if="!state">
            <b-form-invalid-feedback>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
export default {
    name: 'form-row-edit-checkbox',
    props: {
        labelColsLg: {
            type: Number,
            default: 11,
        },
        labelColsSm: {
            type: Number,
            default: 11,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            default: false,
        },
        v: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: '',
        },
        align: {
            type: String,
            default: 'text-right',
        },
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            },
        },
        id() {
            return 'form_row_edit_checkbox_' + this._uid;
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
            }
            return '';
        },
    },
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>

<template>
    <b-container fluid class="top_info-line">
        <b-container style="max-height: 100px">
            <b-row>
                <b-col>
                    <div class="w-100" style="height: 36px; display: flex; align-items: center">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <div class="d-flex times">
                                <div class="today" style="margin-right: 42px">
                                    <span class="grey-color fs-12">{{ kgDate }} ({{ weekday }})</span>
                                </div>
                                <div class="local_time mr-4">
                                    <img src="/images/clock.svg" alt="clock" />
                                    <span class="ml-2 grey-color fs-12">Бишкек &mdash; {{ kgTime }}</span>
                                </div>
                            </div>
                            <div class="d-flex ml-auto center-768">
                                <div class="ml-4">
                                    <a :href="$links.legal_documents" class="grey-color fs-12 fw-600 text-nowrap cursor-pointer" target="_blank">ПРАВОВЫЕ ДОКУМЕНТЫ</a>
                                </div>
                                <div class="ml-4">
                                    <a :href="$links.info_materials" class="grey-color fs-12 fw-600 text-nowrap cursor-pointer" target="_blank">ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
import { DateTime } from 'luxon';

export default {
    name: 'top-info-line',
    data() {
        return {
            kgDate: null,
            kgTime: null,
            weekday: null,
        };
    },
    created() {
        this.setTime();
        setInterval(this.setTime, 10000);
        this.windowWidth = window.innerWidth;
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        setTime() {
            const time = DateTime.now().setZone('Asia/Bishkek').setLocale('ru');
            this.kgDate = time.toFormat('dd.LL.yyyy');
            this.kgTime = time.toFormat('HH:mm');
            this.weekday = time.toFormat('cccc');
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
    },
};
</script>

<style scoped>
i:hover {
    color: var(--est-blue-2);
}

.center-768 a:hover {
    color: var(--est-blue-2);
}

.fast_links div + div {
    margin-left: 10px;
}

.top_info-line {
    background-color: var(--greyBg-color);
}

.fast_links div > a {
    position: relative;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--grey-color);
    font-weight: 600;
    text-decoration: none;
    border-bottom: 2px solid transparent;
}

.right_line > a::after {
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    background-color: #f5f5f6;
    position: absolute;
    right: -20px;
    top: 0;
}

.right_line:last-child > a::after {
    content: none;
    display: none;
}

.nav_line {
    background-color: white;
    -webkit-box-shadow: 0 2px 5px -4px #aaa;
    -moz-box-shadow: 0 2px 5px -4px #aaa;
    box-shadow: 0 2px 5px -4px #aaa;
}

.full-screen {
    position: absolute;
    width: 100%;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: -1;
}

.full_menu-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.list-item {
    margin: 5px 0;
}

.full_menu-list .list-item a {
    color: var(--grey-color);
    font-size: 14px;
    text-decoration: none;
}

.router-link-active {
    color: #333333 !important;
    border-bottom: 2px solid #333 !important;
}

.feedback_modal input::-webkit-input-placeholder,
.feedback_modal textarea::-webkit-input-placeholder {
    color: rgba(149, 155, 164, 0.6) !important;
}

.feedback_modal input:-moz-placeholder,
.feedback_modal textarea:-moz-placeholder {
    color: rgba(149, 155, 164, 0.6) !important;
}

.feedback_modal input::-moz-placeholder,
.feedback_modal textarea::-moz-placeholder {
    color: rgba(149, 155, 164, 0.6) !important;
}

.feedback_modal input:-ms-input-placeholder,
.feedback_modal textarea:-ms-input-placeholder {
    color: rgba(149, 155, 164, 0.6) !important;
}

.feedback_modal input::-ms-input-placeholder,
.feedback_modal textarea::-ms-input-placeholder {
    color: rgba(149, 155, 164, 0.6) !important;
}

.feedback_modal input::placeholder,
.feedback_modal textarea::placeholder {
    color: rgba(149, 155, 164, 0.6) !important;
}
.feedback_modal input,
.feedback_modal textarea {
    border-color: rgba(149, 155, 164, 0.6) !important;
}
@media screen and (max-width: 1200px) {
    .desktop_link {
        display: none !important;
    }
}

@media screen and (max-width: 767px) {
    .times {
        display: none !important;
    }

    .center-768 {
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        margin: 0 !important;
    }

    .btn-custom-blue {
        background-color: var(--est-blue-2) !important;
        color: #fff !important;
        min-width: 130px;
        padding: 10px 20px !important;
        line-height: 1 !important;
        font-size: 14px !important;
    }

    .btn-custom-blue:hover {
        background-color: var(--est-blue-2) !important;
        border-color: var(--est-blue-2) !important;
    }

    .fast_auth {
        display: none !important;
    }
}

.btn-custom-blue,
.btn-custom-blue.router-link-active {
    background-color: var(--est-blue-2) !important;
    color: #fff !important;
    min-width: 130px;
    padding: 10px 20px !important;
    line-height: 1 !important;
    font-size: 14px !important;
    border-color: var(--est-blue-2) !important;
    border-bottom-width: 1px !important;
}

.btn-custom-blue:hover {
    background-color: #fff !important;
    color: var(--est-blue-2) !important;
    border-color: var(--est-blue-2) !important;
}
</style>

<style>
.nav_line .fas span {
    display: block;
    width: 18px;
    height: 2px;
    background: var(--grey-color);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.nav_line .fa-bars span + span {
    margin-top: 3px;
}
.nav_line .fa-times span {
    -webkit-transform: rotate(-45deg) translateY(-1px);
    -moz-transform: rotate(-45deg) translateY(-1px);
    -ms-transform: rotate(-45deg) translateY(-1px);
    -o-transform: rotate(-45deg) translateY(-1px);
    transform: rotate(-45deg) translateY(-1px);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
}
.nav_line .fa-times span:nth-child(1) {
    display: none;
}
.nav_line .fa-times span:nth-child(2) {
    -webkit-transform: rotate(45deg) translateY(1px);
    -moz-transform: rotate(45deg) translateY(1px);
    -ms-transform: rotate(45deg) translateY(1px);
    -o-transform: rotate(45deg) translateY(1px);
    transform: rotate(45deg) translateY(1px);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
}
</style>

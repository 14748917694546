<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" :description="description" :label="label + ':'" :label-for="id" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <ul :id="id">
            <li v-for="(address, index) of value" :key="index" class="fs-14 grey-color my-auto col-form-label">
                {{ address.address }}
            </li>
        </ul>
    </b-form-group>
</template>

<script>
export default {
    name: 'text-row-addresses',
    props: {
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Array,
            default: () => [],
        },
        description: {
            type: String,
            default: '',
        },
    },
    computed: {
        id() {
            return 'text_row_addresses_' + this._uid;
        },
    },
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>

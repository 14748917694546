<template>
    <b-modal v-if="!$store.getters.isLoggedIn" :visible="visible" title="Аутентификация при помощи пароля" no-close-on-backdrop hide-header-close no-close-on-esc centered>
        <div class="d-block text-center w-100 m-auto">
            <form-row-edit-email label="Email" :value="$v.form.email.$model" @value="$v.form.email.$model = $event" :v="$v.form.email" />
            <form-row-edit-text type="password" label="Пароль" v-model="$v.form.password.$model" :v="$v.form.password" />
        </div>
        <template #modal-footer>
            <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                <b-button class="mx-1 text-uppercase" variant="custom-outline-secondary" @click="close">Отмена</b-button>
            </b-overlay>
            <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                <b-button :disabled="$v.$invalid" class="mx-1 text-uppercase" variant="custom-blue" @click="authenticate">Авторизоваться</b-button>
            </b-overlay>
        </template>
    </b-modal>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import axios from 'axios';

export default {
    name: 'login-with-password-modal',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    validations() {
        return {
            form: {
                email: {
                    required,
                    email,
                },
                password: {
                    required,
                },
            },
        };
    },
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            isDataSending: false,
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async authenticate() {
            this.isDataSending = true;
            axios
                .post('/auth/login', { credentials: this.form, login_type: 'password' })
                .then((response) => {
                    // устанавливаем выбранный сертификат в храниилище
                    this.$store
                        .dispatch('login', {
                            token: response.data.token,
                            user: response.data.user,
                            organization: response.data.organization,
                            canSign: false,
                        })
                        .then(() => {
                            window.location.assign('/cabinet');
                        });
                })
                .catch((error) => {
                    this.isDataSending = false;
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Ошибка',
                        variant: 'danger',
                    });
                });
        },
    },
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "label-cols-sm": "4",
        "label-cols-lg": "3",
        description: _vm.description,
        label: _vm.label + ":",
        "label-for": _vm.id,
        state: _vm.state,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _c("VueSuggestions", {
        class:
          "form-control fs-14 " +
          (_vm.v !== null ? (_vm.state ? "is-valid" : "is-invalid") : ""),
        attrs: {
          model: _vm.text,
          id: _vm.id,
          placeholder: "Начните вводить адрес",
          options: _vm.suggestionOptions,
          disabled: _vm.disabled,
          state: _vm.state,
        },
        on: {
          "update:model": function ($event) {
            _vm.text = $event
          },
        },
      }),
      !_vm.state
        ? [_c("b-form-invalid-feedback", [_vm._v(_vm._s(_vm.errorMessage))])]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }